import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IViewModeColumnBased,
  IViewModeField,
  IViewModeSortableField,
  IViewModeUserConfiguration,
  IViewModeUserConfigurationColumn,
  IViewModeUserConfigurationColumnsBased,
  ViewModeTableUserConfigurationColumn,
  ViewSortDirection,
  ViewUserConfiguration
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AbstractDecoupledModalComponent } from '../../../../decoupled-modal/models/abstract-decoupled-modal.component';
import { getInSafe, JsonClone, UtilsTypescript } from '../../../../utils/typescript.utils';
import { ViewModeUtils } from '../../../grid/viewmode.utils';
import { ListComponent2Service } from '../../../list.service';
import { ViewsuserconfigchangedAction } from '../../../viewsuserconfigchanged.eventdata';

@Component({
  selector: 'app-view-configuration-selector',
  templateUrl: './view-configuration-selector.component.html'
})
export class ViewConfigurationSelectorComponent
  extends AbstractDecoupledModalComponent implements OnInit {

  availableItems: IViewModeSortableField[];
  selectedItems: IViewModeSortableField[];
  userColumns: IViewModeUserConfigurationColumn[];
  userConfig: ViewUserConfiguration;

  /**
   * Creates a new instance of ViewConfigurationSelectorComponent
   */
  constructor(
    private listComponentConfiguration: ListComponent2Service,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Initializes component
   */
  ngOnInit(): void {
    this.initialize(this.listComponentConfiguration.getUserConfiguration());
    this.listComponentConfiguration.userConfigurationChanged.takeUntil(this.componentDestroyed$).subscribe(o => {
      if (o.refreshAction === ViewsuserconfigchangedAction.ViewModeChange) {
        this.initialize(o.userConfiguration)
      }
    });
  }

  initialize(userConfig: ViewUserConfiguration): void {
    this.userConfig = userConfig;
    const currentViewMode: IViewModeUserConfiguration =
      getInSafe(this.userConfig, x => x.CurrentViewMode, null);

    if (!currentViewMode) {
      return;
    }
    if (!currentViewMode.hasOwnProperty('Columns')) {
      throw new Error('The Current ViewMode must implements IViewModeUserConfigurationColumnsBased')
    }
    const viewModeUserConfig: IViewModeUserConfigurationColumnsBased = currentViewMode as IViewModeUserConfigurationColumnsBased;
    this.initializeUserColumns(JsonClone(viewModeUserConfig));
    this.updateItemsArrays();
  }


  /**
   * Initialize the user columns
   * @param viewModeUserConfig
   * @param userConfig
   */
  protected initializeUserColumns(viewModeUserConfig: IViewModeUserConfigurationColumnsBased): void {
    // Aunque normalmente ya viene precargado y correctamente desde backend,
    // normalizamos aquí la configuración de usuario para asegurarnos de que está OK y en sincronización
    // con lo que está puesto en el ViewMode
    const tableConfig: IViewModeColumnBased = ViewModeUtils.GetCurrentViewModeColumnBasedFromService(this.listComponentConfiguration);
    this.userColumns = viewModeUserConfig.Columns;
    // Quitar aquellas que no están en las columnas disponibles
    this.userColumns = this.userColumns.filter((userColumn) => viewModeUserConfig.Columns.findIndex(c => c.Field === userColumn.Field) !== -1);
    // Añadir las que falten
    UtilsTypescript.ObjectValues(tableConfig.Columns).forEach((c) => {
      if (this.userColumns.findIndex((x) => x.Field === c.Field) !== -1) {
        return;
      }
      if (c.Access === false) {
        return;
      }
      const uc: ViewModeTableUserConfigurationColumn = new ViewModeTableUserConfigurationColumn();

      uc.Field = c.Field;
      uc.Visible = false;
      uc.SortDirection = ViewSortDirection.None;
      uc.Weight = 0;

      this.userColumns.push(uc);
    });
  }

  /**
   * Add all items to selected columns
   */
  addAllItems(): void {
    this.userColumns.forEach(x => x.Visible = true);
    this.updateItemsArrays();
  }

  /**
   * Receiving item selected from child component.
   */
  addItem(event: IViewModeField): void {
    this.userColumns.find(x => x.Field === event.Field).Visible = true;
    this.updateItemsArrays();
  }

  /**
   * closes modal
   */
  closeHandler(): void {
    this.closeModal();
  }

  /**
   * Updates items from configuration
   */
  updateItemsArrays(): void {
    const config: IViewModeColumnBased = ViewModeUtils.GetCurrentViewModeColumnBasedFromService(this.listComponentConfiguration);

    this.availableItems = UtilsTypescript.ObjectValues(config.Columns)
      .filter(x => this.userColumns.filter(o => o.Visible)
        .findIndex(u => u.Field === x.Field) === -1);

    this.selectedItems = UtilsTypescript.ObjectValues(config.Columns)
      .filter(x => this.userColumns.filter(o => o.Visible)
        .findIndex(u => u.Field === x.Field) !== -1);

    this.cdRef.detectChanges();
  }

  /**
   * Remove item
   * @param {IViewModeField} event
   */
  removeDefaultItem(event: IViewModeField): void {
    this.userColumns.find(x => x.Field === event.Field).Visible = false;
    this.updateItemsArrays();
  }

  /**
   * Save data and close modal
   */
  saveAndCloseHandler(): void {
    (this.userConfig.CurrentViewMode as IViewModeUserConfigurationColumnsBased).Columns = this.userColumns;
    this.listComponentConfiguration.setUserConfiguration(this.userConfig);
    this.closeModal();
  }
}
