import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { ChangedetectorService } from '../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../core/changedetector/changedetectoreference';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import * as $ from 'jquery';
import { Guid } from 'guid-typescript';
import { interval } from 'rxjs';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

/**
 * Esta directiva se encarga de:
 *
 * -- Implementar un fix para un bug de posicionamiento en el componente de calendarios al estar incrustado
 * en la API de formularios que tiene el ChangeDetection desactivado por defecto
 * -- Impedir que el calendario se visualice cuando no cabe en la pantalla (Responsive)
 */
@Directive({
  selector: '[appNgxDatepickerChangedetectionFixerDirective]',
  providers: [
    ChangedetectorReference
  ]
})
export class NgxDatepickerChangedetectionFixerDirective extends DestroyableObjectTrait implements OnDestroy {

  _bsDatepicker: BsDatepickerDirective;

  /**
   * Para indicar si el componente ha sido inicializado. Ocurre que durante la inicilización
   * del componente se hace un show/hide lo que lanza los eventos de touch/blur y esto
   * no es correcto porque el usuario realmente no ha tocado los componentes.
   */
  @Output() initialized: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Id del contenedor que se creará en el DOM para alojar el calendario flotante.
   *
   * Hacemos esto porque el componente no permite acceder al calendario, y esta
   * es la única manera de conocer donde estará y poder interactuar con él.
   *
   * @private
   */
  private containerId: string;

  set bsDatepicker(value: BsDatepickerDirective) {
    this._bsDatepicker = value;
    // Creamos un elemento en la DOM para conenter al calendario flotante, y lo asignamos
    // como container al bsDatepicker
    $('body').prepend('<div id="' + this.containerId + '"></div>');
    this.bsDatepicker.container = '#' + this.containerId;
    this.hideCalendar();
    this.initializeComponent(value);
  }

  get bsDatepicker(): BsDatepickerDirective {
    return this._bsDatepicker;
  }

  /**
   * NgxDatepickerChangedetectionFixerDirective's class constructor.
   *
   * @param {ElementRef} elementRef
   * @param {ChangedetectorReference} cdReference
   * @param {ChangedetectorService} cdService
   */
  constructor(
    private elementRef: ElementRef,
    private cdReference: ChangedetectorReference,
    private cdService: ChangedetectorService,
  ) {
    super();
    this.containerId = 'datepicker__' + Guid.create().toString();
  }

  /**
   *
   */
  ngOnDestroy(): void {
    $('#' + this.containerId).remove();
    super.ngOnDestroy();
  }

  /**
   * Tener calendarios flotantes si hago SCROLL es muy molesto, si hago scroll QUITO el calendario.
   *
   * @param event
   */
  @HostListener('window:scroll', ['$event'])
  scrolled(event: Event): void {
    if (this.bsDatepicker.isOpen) {
      this.bsDatepicker.hide();
    }
  }

  /**
   * Al hacer resize, también lo quitamos
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  resized(event: Event): void {
    if (this.bsDatepicker.isOpen) {
      this.bsDatepicker.hide();
    }
  }

  /**
   * El calendario siempre está oculto vía CSS, solo lo pintamos si cabe...
   */
  showIfCalendarFits(): void {

    // Este es el objeto calendario ya pintado/renderizado
    const calendarObject: JQuery<HTMLElement> = $('#' + this.containerId).find('.bs-datepicker').first();

    const calendarWidth: number = calendarObject.width();
    const calendarHeight: number = calendarObject.height();

    const calendarPosition: DOMRect = calendarObject[0].getBoundingClientRect() as DOMRect;

    // Dejo esto comentado por si hay que hacer debug en un futuro....
    // console.log('Calendar size width: ' + calendarPosition.width);
    // console.log('Calendar size height: ' + calendarPosition.height);
    // console.log('Calendar size top: ' + calendarPosition.top);
    // console.log('Calendar size left: ' + calendarPosition.left);
    // console.log('Calendar size bottom: ' + calendarPosition.bottom);
    // console.log('Calendar size right: ' + calendarPosition.right);
    // console.log('Window position top: ' + calendarPosition.top);
    // console.log('Window position bottom: ' + calendarPosition.bottom);

    const topClearance: number = calendarPosition.top;
    const bottomClearance: number = window.innerHeight - calendarPosition.bottom;

    // console.log('Top clearance: ' + topClearance);
    // console.log('Bottom clearance: ' + bottomClearance);

    // Quiero que no se corte, ni por arriba ni por abajo, con una distancia mínima de 3px
    const fitsVertically: boolean = (topClearance > 3) && (bottomClearance > 3);

    // console.log('Fits vertically:' + (fitsVertically === true ? 'yes' : 'no'));

    if (fitsVertically) {
      this.showCalendar();
    } else {
      this.hideCalendar();
    }
  }

  showCalendar(): void {
    // IMPORTANTE: Usamos opacity porque:
    // si usamos display:none, no se renderiza, por lo que no podemos saber si cabe o no
    // si usamos visibility:hidden, aparecen trozos del calendario, ya que este atributo
    // puede ser sobreescrito por los hijos de manera individual
    $('#' + this.containerId).css('opacity', '1');
  }

  hideCalendar(): void {
    // IMPORTANTE: Usamos opacity porque:
    // si usamos display:none, no se renderiza, por lo que no podemos saber si cabe o no
    // si usamos visibility:hidden, aparecen trozos del calendario, ya que este atributo
    // puede ser sobreescrito por los hijos de manera individual
    $('#' + this.containerId).css('opacity', '0');
  }

  /**
   * Initializes the datepicket configuraton.
   *
   * @param {BsDatepickerDirective} datepicker
   */
  initializeComponent(datepicker: BsDatepickerDirective): void {
    datepicker
      .onShown
      .pipe(
        takeUntil(this.componentDestroyed$),
        tap(() => {
          this.onDisplayChanged();
        })
      )
      .subscribe(() => {

        interval(75)
          .pipe(
            takeUntil(datepicker.onHidden),
            filter(() => {
              const empty: boolean = $('#' + this.containerId).find('.bs-datepicker').length === 0;
              return !empty;
            }),
            take(1),
            tap(() => {
              this.showIfCalendarFits();
            })).subscribe();
      });

    datepicker
      .onHidden
      .pipe(
        takeUntil(this.componentDestroyed$),
        tap(() => {
          this.hideCalendar();
        })
      )
      .subscribe(this.onDisplayChanged.bind(this));

    // Avisar de que ya estamos inicializados
    this.initialized.emit(true);
  };

  /**
   * Re-render the application if needed
   */
  onDisplayChanged(): void {
    this.cdReference.changeDetector.detectChanges();
    this.cdService.runApplicationChangeDetection();
  }
}
