import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { from } from 'rxjs';
import { finalize, map, onErrorResumeNext } from 'rxjs/operators';
import * as StackTrace from 'stacktrace-js';

import { PrimeUtils } from '../../shared/utils/prime.utils';
import { NotHandledError } from './not-handled-error.class';
import { AppConfigurationService } from '../../app.configuration.service';
import { HttpHeaders } from '@angular/common/http';
import { WindowRef } from '../../shared/utils/browser-globals';
import { FrontendError } from '../models/ETG_SABENTISpro_Application_Core_models';
import StackFrame = StackTrace.StackFrame;
import { LogService } from '../services/ETG_SABENTISpro_Application_Core_log.service';

@Injectable()
export class ErrorService implements ErrorHandler {

  constructor(
    private injector: Injector,
    private windowRef: WindowRef,
    private appConfigurationService: AppConfigurationService
  ) {
  }

  private errorPageInAppConfigurationIsDisabled(): boolean {
    return this.appConfigurationService.errorPageIsDisabled;
  }

  handleError(error: Error | NotHandledError | any): void {
    if (error instanceof NotHandledError) {
      console.log('Not Handled Error: ' + error.toString());
      return;
    }
    const loggingService: LogService = this.injector.get(LogService);

    const location: any = this.injector.get(LocationStrategy);

    console.error(error);

    let message: any = null;
    let stackString: string;
    if (error !== null && error !== undefined) {
      message = error.message ? error.message : error.toString();
      stackString = error.stack ? error.stack.toString() : error.stack;
    }
    const url: string = location instanceof PathLocationStrategy ? location.path() : '';
    const device: string = PrimeUtils.GetDevice();

    // Indica que ha habido un error para que los tests e2e puedan verificarlo
    this.windowRef.getNativeWindow().SABENTIS_ERROR = true;

    // Get a stack trace from a error
    from(StackTrace.fromError(error))
      .pipe(
        map((stackFrame: StackFrame[]) => {
          // Hacemos un stack trace más amigable...
          stackString = '';
          for (const frame of stackFrame) {
            stackString += `${frame.functionName} in ${frame.fileName}:line ${frame.lineNumber}` + '\n';
          }
          console.log(stackString);
        }),
        onErrorResumeNext(),
        finalize(() => {
          const logError: FrontendError = new FrontendError();

          logError.Path = url;
          logError.Message = message;
          logError.StackTrace = stackString;
          logError.TicketId = this.randomString(9);
          logError.Timestamp = new Date();
          logError.InfoPlatformBrowser = device;

          // Usamos meta-donothandleerrors porque no queremos que el envío de un error
          // lance la gestión centralizada de errores
          loggingService.postError(logError, {headers: new HttpHeaders({'meta-donothandleerrors': 'true'})})
            .pipe(
              onErrorResumeNext(),
              finalize(() => {
                // we use window.location.href because due to crash angular router could stop working well
                const encodedUrl: string = encodeURIComponent(url);
                if (this.shouldRedirectToErroPage()) {
                  window.location.href = window.location.origin + '/error?id=' + logError.TicketId + '&path=' + encodedUrl;
                } else {
                  // Modal nativa, para que el desarrollador no pueda ignorar el error.
                  this.windowRef.getNativeWindow()
                    .alert('Se ha producido un error no controlado en frontend (ticket ' + logError.TicketId + '), revise la consola para más detalles: ' + logError.Message);
                }
              }))
            .subscribe(
              (result) => {
              },
              (e) => {
                console.log('error during error log callback.', e);
              });
        })).subscribe();

    // IMPORTANT: Rethrow the error is mandatory. Otherwise it gets swallowed
    // throw error;
  }

  /**
   * Check if we should or not redirect to the error page.
   * @returns {boolean}
   */
  protected shouldRedirectToErroPage(): boolean {

    // If it is explictly disabled through query string
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    if (urlParams.has('disableErrorPage')) {
      return false;
    }

    // If we are already in the error page
    if (window.location.pathname === '/error') {
      return false;
    }

    if (this.errorPageInAppConfigurationIsDisabled()) {
      return false;
    }

    // If we are in dev mode
    if (isDevMode()) {
      return false;
    }

    return true;

  }

  /**
   * Generates a random "n-length" string.
   *
   * Used by handleError to generate a random log/ticket identifier.
   * @param {number} length Length of the string to generate.
   */
  randomString(length: number): string {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1).toUpperCase();
  }
}
