/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class MaintenanceModeService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Check the MaintenanceMode
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {boolean}
  */
  getMaintenancemode(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<boolean>>
  {
    return this.generalService.get('core-maintenance-mode/maintenanceMode', null, clientOpts) as Observable<WebServiceResponseTyped<boolean>>;
  }
}
