import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FormElementCheckbox } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-input-check-simple',
  templateUrl: './input-check-simple.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputCheckSimpleComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputCheckSimpleComponent), multi: true}
  ]
})
export class InputCheckSimpleComponent extends FrontendFormElementInput implements AfterViewInit, OnInit {

  checkedValue: boolean;
  @ViewChild('checkElement') checkElement: ElementRef;

  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected zone: NgZone,
              protected translatorService: TranslatorService) {
    super(formManagerService, cdRef, translatorService);
  }

  get elementConfig(): FormElementCheckbox {
    return this.config.FormElement as FormElementCheckbox;
  }

  /**
   * Este tooltip solo se muestra si el campo está deshabilitado
   */
  get toolTipDisabled(): string {
    if (this.getIsDisabled()) {
      return this.elementConfig.ComponentToolTipDisabled;
    }
    return null;
  }

  get checked(): boolean {
    return this.checkedValue;
  }

  set checked(value: boolean) {
    this.checkedValue = value;
    this.propagateChange(value);
  }

  writeValue(value: boolean): void {
    this.checkedValue = value;
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    if (this.checkElement && this.checkElement.nativeElement) {
      this.checkElement.nativeElement.focus();
      return true;
    }
    return false;
  }
}
