import { NgModule } from '@angular/core';
import { NgxDatepickerChangedetectionFixerDirective } from './ngx-datepicker-changedetection-fixer.directive';
import { NgxSelectChangedetectionFixerDirective } from './ngx-select-changedetection-fixer.directive';
import { ScrollPropagateToWindowDirective } from './scroll-propagate-to-window.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    BsDatepickerModule
  ],
  declarations: [
    NgxDatepickerChangedetectionFixerDirective,
    NgxSelectChangedetectionFixerDirective,
    ScrollPropagateToWindowDirective
  ],
  exports: [
    NgxDatepickerChangedetectionFixerDirective,
      NgxSelectChangedetectionFixerDirective,
    ScrollPropagateToWindowDirective
  ]
})

export class SabentisUtilsModule {
}
