<div class="dropdown">
  <button class="btn-dropdown" type="button" id="dropdownMenu1" aria-haspopup="true" aria-expanded="true"
          (click)="toggle()">
    <i class="fa fa-angle-double-down fa-lg"></i>
  </button>

  <!-- Ponemos el ngIf open porque la implementación de este menú flotante hace un forced-reflow! -->
  <app-dropdown-menu-child *ngIf="open"
                           [children]="tree?.children"
                           [open]="open"
                           [direction]="direction"
                           [isRoot]="true"
                           (childEvents)="propagateEvent($event)"></app-dropdown-menu-child>

</div>
