<ng-container *ngFor="let rowGroup of groupData; index as i; trackBy: trackGroup">
  <tr class="view-table-body-tr-headergroup t-view-groupheader" [class.even-row]="i%2===0">
    <td [attr.colspan]="fullColspanLength()">
      <div class="header-container">
        <div class="group-collapse" [class.hidden]="!groupBy.GroupByHeaderCollapsible">
          <button
              class="views-group-header-collapse-button t-view-group-collapsetoogle reset-button"
              [class.glyphicon]="true"
              [class.glyphicon-chevron-right]="this.groupCollapsed(rowGroup.Id)"
              [class.glyphicon-chevron-down]="!this.groupCollapsed(rowGroup.Id)"
              (click)="toogleCollapseGroup(rowGroup)"
              type="button"></button>
        </div>
        <div [innerHTML]="rowGroup?.GroupHtmlSummary" class="group-content"
             (click)="toogleCollapseGroup(rowGroup)"></div>
        <div class="group-buttons" [class.hidden]="!rowGroup.Operations">
          <ng-container *ngFor="let operation of asIterableObject3(rowGroup.Operations); index as operationIndex;">
            <button (click)="operationOnClick(operation)"
                    [title]="operation?.Tooltip ?? ''"
                    [ngClass]="getOperationClasses(operation, operationIndex)">{{operation.Label}}</button>
          </ng-container>
        </div>
      </div>
    </td>
  </tr>

  <!--Message for empty groups, otherwise render rows in group.-->
  <tr class="view-table-body-tr grouped" *ngIf="rowGroup.EmptyGroup; else rows"
      [class.hidden]="this.groupCollapsed(rowGroup.Id)">
    <td class="view-table-body-tr-td empty-group" [attr.colspan]="fullColspanLength()">
      {{rowGroup.EmptyGroupText}}
    </td>
  </tr>

  <ng-template #rows>

    <tr class="view-table-body-tr grouped"
        *ngFor="let rowResult of getGroupResults(rowGroup); index as i; trackBy: trackElement"
        [class.even-row]="i%2===0"
        [ngClass]="resolveRowClasses(rowResult)"
        [class.hidden]="this.groupCollapsed(rowGroup.Id)">

      <td class="view-table-body-tr-td" *ngFor="let fieldInclude of headerFields"
          [ngClass]="resolveTableCellClasses(fieldInclude, rowResult)">

        <app-view-grid-cell
            *ngIf="checkFieldTypeInTypes(rowResult, fieldInclude, [ViewResultCellType.Simple, ViewResultCellType.FileLink, ViewResultCellType.NavigationNodeLink, ViewResultCellType.Html])"
            [viewModeUserColumn]="fieldInclude" [rowData]="rowResult">
        </app-view-grid-cell>

        <app-singleitemoperations2 [row]="rowResult"
                                   *ngIf="checkFieldType(rowResult, fieldInclude) == ViewResultCellType.SingleItemOperation">
        </app-singleitemoperations2>

        <app-view-grid-cell-bulk-operations
            [fieldInclude]="fieldInclude"
            [rowData]="rowResult"
            *ngIf="checkFieldType(rowResult, fieldInclude) == ViewResultCellType.BulkOperation">
        </app-view-grid-cell-bulk-operations>
      </td>
    <tr>
  </ng-template>
</ng-container>

