import { Component, EventEmitter, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ListComponent2Service } from '../../../../list.service';
import { DestroyableObjectTrait } from '../../../../../utils/destroyableobject.trait';
import {
  ViewConfiguration,
  ViewQuickSearch,
  ViewQuickSearchFieldSimple,
  ViewsQuickSearchUserConfiguration,
  ViewUserConfiguration
} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ChangedetectorReference } from '../../../../../../core/changedetector/changedetectoreference';
import { isNullOrWhitespace, UtilsTypescript } from '../../../../../utils/typescript.utils';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-view-searcher-new',
  templateUrl: './view-searcher-new.component.html',
  styleUrls: ['./view-searcher-new.component.scss'],
  providers: [ChangedetectorReference]
})
export class ViewSearcherNewComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  /**
   * Si está abierto o no el selector de campos
   */
  showSearchByList: boolean = false;

  userConfiguration: ViewUserConfiguration;

  viewConfiguration: ViewConfiguration;

  searchString: string;

  detectChangesDebouncer: EventEmitter<void> = new EventEmitter<void>();

  uniqueComponentId: string;

  /**
   * Get an instance of ViewSearcherNewComponent
   * @param listService
   * @param changeDetector
   */
  constructor(
    public listService: ListComponent2Service,
    private changeDetector: ChangedetectorReference) {
    super();
    this.uniqueComponentId = Guid.create().toString();
  }

  ngOnInit(): void {

    this.detectChangesDebouncer
      .pipe(
        debounceTime(300),
        takeUntil(this.componentDestroyed$),
        takeUntil(this.listService.componentDestroyed$)
      )
      .subscribe((i) => this.changeDetector.changeDetector.detectChanges());

    this.viewConfiguration = this.listService.getConfiguration();

    this.listService.activeUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$),
        takeUntil(this.listService.componentDestroyed$)
      )
      .subscribe((i) => {
        this.userConfiguration = i.userConfiguration;
        if (!this.userConfiguration.Search) {
          this.userConfiguration.Search = new ViewsQuickSearchUserConfiguration();
        }
        this.searchString = this.userConfiguration.Search.SearchString;
        this.changeDetector.changeDetector.detectChanges();
      });
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return 'view-searcher-new';
  }

  /**
   * Obtener la configuración de la búsqueda rápida
   */
  get quickSearchConfiguration(): ViewQuickSearch {
    return this.viewConfiguration.QuickSearch;
  }

  /**
   * The current views quick search user configuration
   */
  get quickSearchUserConfiguration(): ViewsQuickSearchUserConfiguration {
    return this.userConfiguration.Search;
  }

  /**
   * Obtiene los campos por los que se puede buscar, siempre debería haber algo...
   */
  get fields(): ViewQuickSearchFieldSimple[] {
    if (this.quickSearchConfiguration && this.quickSearchConfiguration.Fields) {
      // Ordenamos alfabéticamente los campos, mejor hacerlo aquí para no tener que traducir en backend ya que
      // la mayoría de estos locales se hacen a nivel de serializador
      return this.quickSearchConfiguration.Fields.sort((a, b) => a.Name > b.Name ? 1 : -1);
    }
    return [];
  }

  /**
   * Method to check if field is or not selected
   * @param field
   * @returns {boolean}
   */
  isChecked(field: string): boolean {
    return this.quickSearchUserConfiguration.Fields.indexOf(field) !== -1;
  }

  /**
   * Si hay o no campos disponibles para la búsqueda, en caso de no haber, el control se desactiva.
   */
  get hasFields(): boolean {
    return this.quickSearchUserConfiguration?.Fields?.length > 0;
  }

  /**
   * Resumen de los campos en que busca
   */
  get quickSearchFieldSummary(): string {

    if (!this.quickSearchUserConfiguration || !this.quickSearchConfiguration) {
      return '';
    }

    const fields: string = this.quickSearchConfiguration.Fields.filter((i) => this.quickSearchUserConfiguration.Fields.includes(i.FieldId))
      .map((i) => i.Name)
      .join(' + ');

    return fields;
  }

  get quickSearchFieldSummaryDisplay(): string {

    const result: string = this.quickSearchFieldSummary;

    if (UtilsTypescript.isNullOrWhitespace(result)) {
      return 'Buscar por';
    }

    return result;
  }

  /**
   * Method to check if field is or not selected
   * @param field
   * @returns {boolean}
   */
  isDisabled(field: string): boolean {
    if (this.isChecked(field)) {
      return false;
    }
    if (this.quickSearchUserConfiguration.Fields.length >= this.quickSearchConfiguration.MaxConcurrentFields) {
      return true;
    }
    return false;
  }

  /**
   * Event when user click outside the drop-down
   * to close it. Uses clickOutside directive
   * @param event
   */
  clickOutsideHandler(event: any): void {
    this.showSearchByList = false;
    this.changeDetector.changeDetector.detectChanges();
  }

  /**
   *
   * @param field
   */
  itemTooltip(field: string): string {
    if (this.isDisabled(field)) {
      return 'No puede seleccionar más de ' + this.quickSearchConfiguration.MaxConcurrentFields + ' campos al mismo tiempo.';
    }
    return null;
  }

  /**
   * En every check or uncheck of a field on the drop-down, search fields array
   * changes
   * @param event
   */
  inputChanged(event: any, fieldId: string): void {
    // Emular el disable
    if (this.isDisabled(fieldId)) {
      event.preventDefault();
      event.target.checked = false;
      return;
    }
    if (event.target.checked) {
      this.quickSearchUserConfiguration.Fields.push(fieldId);
    } else {
      this.quickSearchUserConfiguration.Fields.splice(this.quickSearchUserConfiguration.Fields.indexOf(fieldId), 1)
    }
    this.changeDetector.changeDetector.detectChanges();
  }

  /**
   * On input submit, the user configuration is applied with the search string
   * and the given Array of fields to search in.
   */
  searchChange(event: UIEvent): void {

    this.detectChangesDebouncer.emit();

    const currentSearchNormalized: string = isNullOrWhitespace(this.userConfiguration.Search.SearchString) ? '' : this.userConfiguration.Search.SearchString.trim();
    const newSearchNormalized: string = isNullOrWhitespace(this.searchString) ? '' : this.searchString.trim();

    const searchCleared: boolean = !isNullOrWhitespace(currentSearchNormalized)
      && isNullOrWhitespace(newSearchNormalized);

    /**
     * If the event is a `KeyboardEvent` and doesn't corresponds to a
     * enter key then return.
     */
    if (this.keyboardEventIsNotEnterKey(event) && !searchCleared) {
      return;
    }

    // Prevent default behaviour on enter-like keys and button submit.
    this.preventEventPropagation(event);

    // Si no ha cambiado no hago nada
    if (currentSearchNormalized === newSearchNormalized) {
      return;
    }

    // Lo que hacemos es actualizar la user configuration
    this.userConfiguration.Search.SearchString = this.searchString;

    // Reset the page number if the configuration changes
    this.listService.setPaginatorFirstPage();

    // Update the user configuration
    this.listService.setUserConfiguration(this.userConfiguration);
  }

  /**
   * This method is called on `search input keypress`. And prevents default
   * behaviour on input elements.
   *
   * This was added as views can be a component for forms, and keypress can
   * propagate auto-submit events.
   *
   * @param {Event}event
   */
  preventEventPropagation(event: Event): void {
    if (this.keyboardEventIsNotEnterKey(event)) {
      return;
    }
    event.preventDefault();
  }

  inputFocus(event: Event): void {
    if (this.quickSearchUserConfiguration.Fields.length === 0) {
      event.stopPropagation();
      this.showSearchByList = true;
      this.changeDetector.changeDetector.detectChanges();
      return;
    }
    event.preventDefault();
  }

  /**
   * Retuns a boolean indicating true when an even is instance of `KeyboardEvent`
   * and it's code represents an Enter key.
   *
   * @param {Event} event
   */
  protected keyboardEventIsNotEnterKey(event: Event): boolean {
    return event instanceof KeyboardEvent && !((event as KeyboardEvent).code.includes('Enter') || (event as KeyboardEvent).key.includes('Enter'));
  }

  /**
   * Toggle dropdown visibility
   */
  toggleDropdown(event: Event): void {
    // Si no tengoo campos, no abro nada.
    if (!this.hasFields) {
      return;
    }
    this.showSearchByList = !this.showSearchByList;
    this.changeDetector.changeDetector.detectChanges();
    this.preventEventPropagation(event);
  }
}
