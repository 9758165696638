<!--
Mantenemos el comportamiento legacy de una mono-operación para reducir el impacto en las implementaciones actuales,
el control automáticamente hará un promote a la implementación basada en select-list cuando exista más de una operación.
La implementación mono-operación debería DESAPARECER.
-->
<ng-container *ngIf="hasOperations">
  <ng-container *ngIf="showLegacyVbo">
    <div [title]="getOperation()?.Tooltip">
      <button type="button"
              mat-button
              [ngClass]="getButtonClasses(getOperation())"
              (click)="onExecuteOperationClickHandler(getOperation())">
        <i [ngClass]="getIconClasses(getOperation())"></i>{{ getOperation().Title }}
      </button>
    </div>
  </ng-container>
  <!--Fin de comportamiento legacy-->
  <ng-form *ngIf="!showLegacyVbo">
    <fieldset [disabled]="!anyVboSelected()">
      <select [(ngModel)]="selectedOperation"
              (change)="detectChanges()"
              (blur)="onBlur()"
              [ngModelOptions]="{standalone: true}"
              [title]="selectListToolTip()"
              [id]="selectListId()">
        <option [id]="selectListId(operation.key)" *ngFor="let operation of operationOptions | keyvalue"
                value="{{operation.key}}">{{operation.value}}</option>
      </select>
      <button (click)="onExecuteOperationFromSelectClickHandler()"
              title="{{ 'Ejecutar operación' | t }}"
              class="c-btn-refresh"
              [class.--disabled]="!operationSelected"
              [disabled]="!operationSelected">
        <i class="fas fa-sign-in-alt"></i>
      </button>
    </fieldset>
  </ng-form>
</ng-container>
