<div fxLayout="row wrap"
     fxFlex="100"
     fxFill
     fxHide.xs
     fxLayoutAlign="start center"
     *ngIf="viewStorageIsEnabled">
  <div [title]="'Carga una consulta' | t">
    <button mat-button class="c-btn-link"
            (click)="loadUserViewConfiguration()">
      <i class="fas fa-upload"></i>
      {{'Cargar consulta' | t }}
    </button>
  </div>
  <div *ngIf="currentUserConfigurationIsCustomized"
       [title]="'Guarda la consulta actual y queda disponible para usarla posteriormente' | t">
    <button mat-button class="c-btn-link"
            (click)="saveUserViewConfiguration()">
      <i class="fas fa-save"></i>
      {{'Guardar consulta actual' | t }}
    </button>
  </div>
  <div *ngIf="currentUserConfigurationIsCustomized"
      [title]="'Descarta la consulta y reestablece la generada por defecto. Descartará cualquier filtro, ordenación y volverá a la primera página' | t">
    <button mat-button class="c-btn-link"
            (click)="dropStoredUserConfigurationAndRefresh()">
      <i class="fas fa-trash-alt"></i>
      {{'Borrar consulta y restablecer' | t }}
    </button>
  </div>


</div>
<ng-template #saveConfigurationModal>
  <form name="loginForm" [formGroup]="formGroupSaveConfiguration" novalidate
        fxFlex="100"
        fxLayout="column">
    <label for="name">{{ 'Nombre de la consulta' | t }}</label>
    <input type="text" class="form-control" formControlName="name" name="name">
    <label for="description">{{ 'Descripción de la consulta' | t }}</label>
    <textarea formControlName="description" name="description"></textarea>
    <div style="display: flex; justify-content: center; margin-top: 20px;">
      <button type="button" class="o-btn__danger" style="margin-right: 26px"
              (click)="closeHandler()">{{ 'Cancelar' | t }}</button>
      <button type="button" class="o-btn__primary" (click)="saveConfiguration()"
              [disabled]="formGroupSaveConfiguration.invalid">{{ 'Guardar' | t }}</button>
    </div>
  </form>
</ng-template>

<ng-template #loadConfigurationModal>
  <form name="loginForm" [formGroup]="formGroupLoadConfiguration" novalidatefxFlex="100"
        fxLayout="column">
    <span  *ngIf="options?.length === 0">
      {{'No hay consultas almacenadas disponibles. Genere una nueva consulta y guárdela para seleccionarla después.' | t}}</span>
    <label *ngIf="options?.length > 0" for="query">{{ 'Consulta' | t }}</label>
    <select *ngIf="options?.length > 0"  formControlName="query" name="query">
      <option *ngFor="let option of options" [value]="option.Id">{{ option.Name }}</option>
    </select>
    <div style="display: flex; justify-content: center; margin-top: 20px;">
      <button type="button" class="o-btn__secondary" (click)="closeHandler()" style="margin-right: 26px">{{ 'Cancelar' | t }}</button>
      <button *ngIf="options?.length > 0" type="button" class="o-btn__danger" (click)="deleteConfigurationTemplate()" style="margin-right: 26px"
              [disabled]="formGroupLoadConfiguration.invalid">{{ 'Eliminar' | t }}</button>
      <button *ngIf="options?.length > 0" type="button" class="o-btn__primary" (click)="loadConfigurationTemplate()"
              [disabled]="formGroupLoadConfiguration.invalid">{{ 'Aceptar' | t }}</button>
    </div>
  </form>
</ng-template>

<!--
<div [title]="'Añade o quita columnas al listado' | t">
  <button mat-button (click)="showColumnConfiguration()">
    <i class="fas fa-cogs"></i>
    {{'Añadir / quitar columnas' | t }}
  </button>
</div>
<ng-template #columnsModal>
  <app-view-configuration-selector (close)="modalRef.doClose()">
  </app-view-configuration-selector>
</ng-template>
-->
