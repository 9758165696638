import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ChangedetectorReference } from '../../../../core/changedetector/changedetectoreference';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import {
  asIterable,
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isNullOrUndefined,
  JsonClone,
  UtilsTypescript
} from '../../../utils/typescript.utils';
import { SingleItemOperationEventData2 } from '../../events/singleitemoperation.eventdata';
import { ListComponent2Service } from '../../list.service';
import { ViewOperationFormModalComponent } from './view-operation-form-modal.component';
import { DecoupledModalBridgeService } from '../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { CommandService } from '../../../../core/commands/command.service';
import { TranslatorService } from '../../../../core/translator/services/rest-translator.service';
import {
  CoreOpenFormInModalCommand,
  DtoFrontendModalType,
  ICommand,
  IVboOperation,
  SingleItemOperationTypeEnum,
  VboExportOperation,
  ViewConfiguration,
  ViewOperationBase,
  ViewOperationCommand,
  ViewsSingleItemOperationVboLoop,
  ViewsVboSelectedItem,
  ViewsVboUserConfiguration,
  ViewUserConfiguration
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { CORE_QUEUE } from '../../../../core/models/ETG_SABENTISpro_Models_models';

@Component({
  selector: 'app-view-operations',
  templateUrl: './view-operations.component.html',
  styleUrls: ['./view-operations.component.scss'],
  providers: [ChangedetectorReference]
})
export class ViewOperationsComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  configuration: ViewConfiguration;

  /**
   * Operaciones VBO de este listado
   */
  operations: { [key: string]: IVboOperation } = {};

  selectedOperation: string;

  noOperationValue: string = 'no-operation';

  @Output() onVboOperationCompleted: EventEmitter<{ operation: IVboOperation, result: { id: string, message: any[], responseData: any } | CORE_QUEUE }> =
    new EventEmitter<{ operation: IVboOperation, result: { id: string, message: any[], responseData: any } | CORE_QUEUE }>();

  /**
   * Instance of the class.
   */
  constructor(
    private listService: ListComponent2Service,
    private cdRef: ChangedetectorReference,
    private dmbs: DecoupledModalBridgeService,
    private commandService: CommandService,
    private translatorService: TranslatorService
  ) {
    super();
    this.configuration = this.listService.getConfiguration();
  }

  get operationSelected(): boolean {
    return this.selectedOperation !== this.noOperationValue;
  }

  ngOnInit(): void {
    this.selectedOperation = this.noOperationValue;
    this.operations = getInSafe(this.listService, (i) => i.getConfiguration().Operations, {});

    this.listService.vboSelectedRowsChanged
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.cdRef.changeDetector.detectChanges();
      });

    this.listService.onSingleItemOperation
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(this.onSingleItemOperation.bind(this));
  }

  /**
   * Este método es para hacer el puente entre los SIO de tipo vboLoop
   * y las operaciones.
   */
  onSingleItemOperation(event: SingleItemOperationEventData2): void {

    // Si no es vboLoop, no hacer nada
    if (!event || !event.operation) {
      return;
    }

    if (event.operation.Type !== SingleItemOperationTypeEnum.VboLoop) {
      return;
    }
    const sio: ViewsSingleItemOperationVboLoop = event.operation as ViewsSingleItemOperationVboLoop;

    // Buscamos la operación que toque..
    const operation: IVboOperation = UtilsTypescript.ObjectValues(this.operations).find((i) => i.Id === sio.VboTarget);
    if (isNullOrUndefined(operation)) {
      return;
    }

    // Hay que clonar la user configuration y manipular la selección Vbo
    const viewUserConfiguration: ViewUserConfiguration = UtilsTypescript.jsonClone(this.listService.getUserConfiguration());
    const selectedItem: ViewsVboSelectedItem = event.row.Metadata['vbo_vbo'] as ViewsVboSelectedItem;

    // Esta la montamos a mano
    const vboUserConfiguration: ViewsVboUserConfiguration = new ViewsVboUserConfiguration();
    vboUserConfiguration.Id = 'vbo';
    vboUserConfiguration.AllItemsInAllPages = false;
    vboUserConfiguration.SelectedItems = {};
    vboUserConfiguration.SelectedItems[selectedItem.Id] = selectedItem;
    viewUserConfiguration.UserConfigurations['vbo'] = vboUserConfiguration;

    const viewConfiguration: ViewConfiguration = this.configuration;
    this.executeOperation(viewConfiguration, operation);
  }

  /**
   * Available operation options for the drop-down
   */
  get operationOptions(): { [key: string]: string } {
    const result: any = {};

    // Dejo esto comentado: antes había un IF, y lo que se intentaba era que una vez seleccionado un ítem, ya
    // no hubiera opción de volver a la selección vacía, pero al cambiar la batería de opciones disponibles durante la propia
    // selección el componente se pierde la selección y sincronización entre el valor del componente y lo que
    // tenemos bindeado en el modelo de anulgar
    // if (UtilsTypescript.isNullOrWhitespace(this.selectedOperation) || this.selectedOperation === this.noOperationValue) {
    //  result[this.noOperationValue] = '-- Operación --';
    // }
    result[this.noOperationValue] = this.translatorService.get('Seleccione');

    for (const key in this.operations) {
      if (this.operations[key]) {
        result[key] = this.operations[key].Title;
      }
    }
    return result;
  }

  /**
   * Number of available VBO operations for this list
   */
  get hasOperations(): boolean {
    return this.operations && Object.keys(this.operations).length > 0;
  }

  get showLegacyVbo(): boolean {
    // Si pido explícitamente el display avanazado
    if (this.listService.getConfiguration().OperationsConfiguration?.ForceDisableLegacyDisplay === true) {
      return false;
    }

    // Caso contrario, muestro legacy si solo tengo una operación
    return Object.keys(this.operations).length === 1;
  }

  /**
   * The component id to use for the select list
   */
  selectListId(suffix: string = ''): string {
    return 'list-vbo-select-' + this.listService.currentPluginRequest?.Id + suffix;
  }

  getActiveOperationDescription(): string {
    return this.operations[this.selectedOperation]?.Description
  }

  /**
   * Para poder ejectuar una opceración VBO hay que seleccionar al menos
   * un elemento en el listado
   */
  anyVboSelected(): boolean {
    return this.listService.hasAnyVboSelected()
  }

  /**
   * Tooltipo for the select list.
   */
  selectListToolTip(): string {
    if (!this.anyVboSelected()) {
      return 'Debe seleccionar al menos un elementos del listado para ejecutar una operación.';
    }
    return this.getActiveOperationDescription() ? this.getActiveOperationDescription() : '';
  }

  onBlur(): void {
  }


  onExecuteOperationFromSelectClickHandler(): void {
    this.executeOperation(this.configuration, this.operations[this.selectedOperation]);
  }

  onExecuteOperationClickHandler(operation: IVboOperation): void {
    this.executeOperation(this.configuration, this.getOperation());
  }

  executeOperation(viewConfiguration: ViewConfiguration, operation: IVboOperation): void {
    if (backendTypeMatch(ViewOperationCommand.$type, operation)) {
      const op: ViewOperationCommand = operation as ViewOperationCommand;
      const command: ICommand = JsonClone(op.Command);
      // Si es un comando de abrir formulario en modal, le propago argumentos
      if (backendTypeMatch(CoreOpenFormInModalCommand.$type, command)) {
        const castedCommand: CoreOpenFormInModalCommand = command as CoreOpenFormInModalCommand;
        castedCommand.Arguments['ViewsPluginRequest'] = JsonClone(this.listService.currentPluginRequest);
        castedCommand.Arguments['UserConfiguration'] = JsonClone(this.listService.getUserConfiguration());
      }
      this.commandService.executeCommandChain([command]);
    } else {

      // Esto está obsoleto, puedes abrir un formulario con el ViewOperationCommand

      const formParams: object = {};
      formParams['configuration'] = viewConfiguration;
      formParams['userConfiguration'] = this.listService.getUserConfiguration();
      formParams['operation'] = operation;

      let modalType: DtoFrontendModalType
      if (backendTypeMatch(VboExportOperation.$type, operation)) {
        const op: VboExportOperation = operation as VboExportOperation;
        modalType = op?.ModalType;
      }

      const ref: ModalReference<unknown> = this.dmbs.showComponent(
        ViewOperationFormModalComponent,
        {
          Title: operation?.Title,
          ModalType: modalType
        },
        formParams
      );

      ref.instance$
        .takeUntil(this.componentDestroyed$)
        .subscribe((component: ViewOperationFormModalComponent) => {
          component.ngOnChanges(null);
          this.cdRef.refreshApp();
        });
    }
  }

  detectChanges(): void {
    this.cdRef.changeDetector.detectChanges();
  }

  /**
   * En caso de solo existir una operación, devuelve esa operación. Sino, NULL.
   */
  getOperation(): ViewOperationBase {
    if (this.operations && Object.keys(this.operations).length === 1) {
      return asIterableObject(this.operations)[0] as ViewOperationBase;
    }
  }

  getButtonClasses(operation: IVboOperation): string[] {
    const classes: string[] = [...asIterable(operation.CssClasses).filter(x => !x.startsWith('icon-'))];
    classes.push(`t-vbo-${operation.Id}`.toLowerCase())
    return classes;
  }

  getIconClasses(operation: ViewOperationBase): string[] {
    const icons: string[] = [];
    if (!isNullOrUndefined(operation.CssClassIcon)) {
      icons.push(operation.CssClassIcon);
    }

    return icons;
  }
}
