import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LayoutManagerService } from '../core/layout-manager/layout-manager.service';
import { BootstrapService } from '../app-bootstrap.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsUrl } from '../utils/utils-url';

/**
 * Este guard se encarga de que la ruta que pedimos esté siempre dentro de layout
 * que esté activo actualmente en la aplicación.
 *
 * * Si la ruta no tiene prefijo de layout, se la pone.
 * * Si la ruta tiene un prefijo de layout diferente al activo, se la cambia.
 *
 * Esto nos permite trabajar con rutas SIN LAYOUT (en los tests, enlaces externos, etc..)
 */
@Injectable()
export class LayoutGuard implements CanActivate {
  constructor(
    private layoutManager: LayoutManagerService,
    private router: Router,
    private bootstrapService: BootstrapService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.bootstrapService
      .bootDataReady$()
      .pipe(
        map((res) => {

          // Hay que mantener esto al día... que palo
          const layoutPrefixes: string[] = ['main', 'fuse'];

          const activeLayoutPrefix: string = this.layoutManager.getCurrentLayoutDefinition().Root;

          const currentLayoutUrl: string = layoutPrefixes.find((i) => i === route.url[0]?.path);
          const currentUrlPathName: string = new URL(UtilsUrl.JoinPath('http://localhost/', state.url)).pathname;

          // Si no tengo un prefijo de layout, se lo pongo
          if (!currentLayoutUrl) {
            const newRoute: string = UtilsUrl.JoinPath(activeLayoutPrefix, currentUrlPathName);
            this.router.navigate([newRoute], {queryParams: route.queryParams, replaceUrl: true});
            return false;
          }

          const currentUrlNoLayout: string = currentUrlPathName.split('/').slice(2).join('/');

          // Si el prefijo está mal, lo cambio
          if ('/' + currentLayoutUrl + '/' !== activeLayoutPrefix) {
            const newRoute: string = UtilsUrl.JoinPath(activeLayoutPrefix, currentUrlNoLayout);
            this.router.navigate([newRoute], {queryParams: route.queryParams, replaceUrl: true});
            return false;
          }

          // Todo está bien
          return true;
        })
      );
  }
}
