import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styles: [`
    .container-table {
      height: 100%;
      margin-top: 15%;
    }
    .container-table {
      display: table;
    }
    .vertical-center-row {
      display: table-cell;
      vertical-align: middle;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .image-wrapper {
      margin-right: 32px;
      padding-bottom: 32px;
    }

    .flex-container {
      display:flex;
      align-items: center;
      justify-content: center;
    }

    .mt16 {
      margin-top: 8px;
    }`]
})
export class ErrorPageComponent implements OnInit {

  errorNumber: string;
  path: string;
  main: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.errorNumber = this.activatedRoute.snapshot.queryParams['id'];
    this.path = 'http://' + window.location.href.split('/')[2] + this.activatedRoute.snapshot.queryParams['path'];
    this.main = 'http://' + window.location.href.split('/')[2];
  }

  /**
   * Go to a page and refresh
   *
   * @param disableErrorgeneric-form-page.component.ts:line 29
   */
  goToPageAndRefresh(disableError: boolean = false): void {
    const uri: URL  = new URL(this.path);
    uri.searchParams.set('disableErrorPage', 'true')
    window.location.href = uri.toString();
  }
}
