import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/authentication/auth.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { WebServiceResponseTyped } from '../core/models/ETG_SABENTISpro_Application_Core_models';
import { SsoService } from '../core/services/ETG_SABENTISpro_Application_Modules_sso.service';
import { AuthenticateResult } from '../core/models/ETG_SABENTISpro_Application_Modules_models';

@Component({
  selector: 'app-singlesignon',
  templateUrl: './singlesignon.component.html'
})

/**
 * This component holds the logic and validations to shoe / edit the configuration data of the application
 */
export class SingleSignOnComponent implements OnInit {

  public token: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ssoService: SsoService,
    private authService: AuthService,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.take(1).subscribe(qp => {
      this.token = qp.token;
      if (this.token) {
        this.ssoService.postAuthenticate(this.token).subscribe((res: WebServiceResponseTyped<AuthenticateResult>) => {
          if (!res.result.valid) {
            (window as any).location.href = res.result.callback_uri;
          } else {
            localStorage.setItem('sso-redirect-uri', res.result.callback_uri);
            if (res.result.url_destination) {
              this.router.navigateByUrl(res.result.callback_uri).then();
            } else {
              this.navigationService.goToHome().then();
            }
          }
        })
      }
    })
  }
}
