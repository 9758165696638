import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageBarService } from '../message-bar/services/message-bar.service';
import { MaintenanceModule } from './maintenance.module';
import { PrimeUtils } from '../../shared/utils/prime.utils';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CommandService } from '../commands/command.service';
import { backendTypeMatch } from '../../shared/utils/typescript.utils';
import { IResultCollector } from '../commands/resultcollector.interface';
import { CoreMaintenanceCommand } from '../models/ETG_SABENTISpro_Application_Core_models';

/**
 * This service subscribes to CommandService and keeps the status
 * of the maintenance mode.
 */
@Injectable({
  providedIn: MaintenanceModule
})
export class MaintenanceControlService extends DestroyableObjectTrait implements OnDestroy {

  /**
   * Flag that indicates if the maintenance mode is active.
   */
  protected maintenanceMode = false;

  /**
   * MaintenanceControlService class constructor.
   */
  constructor(
    private commandService: CommandService,
    private mbService: MessageBarService,
    private router: Router
  ) {
    super();
    this.commandService
      .CommandObservable
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(obj => backendTypeMatch(CoreMaintenanceCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreMaintenanceCommand, (() => Promise<boolean>) | Observable<boolean>>),
      )
      .subscribe((next) => {
        next.AddResult(() => {
          this.activateMaintenanceMode();
          return Promise.resolve(true);
        });
      });
  }

  /**
   * Activates the maintenance mode and navigates to the maintenance page.
   */
  goToMaintenancePage$(): Observable<boolean> {
    this.activateMaintenanceMode();
    return Observable.fromPromise(PrimeUtils.NavigateToMaintenance(this.router));
  }

  /**
   * Activates the maintenance mode and navigates to the maintenance page.
   */
  goToOutOfServicePage$(): Observable<boolean> {
    if (this.router.url.includes('out-of-service')) {
      return Observable.of(true);
    }

    return Observable.fromPromise(this.router.navigate(['/out-of-service']));
  }

  /**
   * Activates the maintenance mode.
   */
  activateMaintenanceMode(): void {
    if (!this.maintenanceModeIsActive()) {
      this.mbService.addMessage(
        `Estamos realizando tareas de mantenimiento.
        Algunas funciones pueden estar desactivadas.`,
        {closeable: false});
    }

    this.maintenanceMode = true;
  }

  /**
   * De-activates the maintenance mode.
   */
  deactivateMaintenanceMode(): void {
    this.maintenanceMode = false;
  }

  /**
   * Returns a boolean indicating if the maintenance mode is active.
   */
  maintenanceModeIsActive(): boolean {
    return this.maintenanceMode;
  }
}
