import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { getInSafe } from '../../shared/utils/typescript.utils';
import { delayWhen, finalize, map, retryWhen, tap } from 'rxjs/operators';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { Guid } from 'guid-typescript';
import { AppBootstrapSpinnerService } from '../../app-bootstrap-spinner.service';
import { WebServiceResponse } from '../models/ETG_SABENTISpro_Application_Core_models';

/**
 * Este interceptor se encarga de gestionar la situación
 * en la que un usuario está "trabajando" en la plataforma
 * y esta recibe un reseteo y se encuentra temporalmente en 503.
 *
 * Esto no evita que se refresque el aplicativo si se detecta un cambio de versión...
 */
@Injectable()
export class AppbootstrapInterceptor implements HttpInterceptor {

  /**
   * Nos traemos los dos servicios de spinner, ya que AppBootstrapSpinnerService sirve para antes de tener
   * cargado nada (no hay theme, ni estilos, etc..) y el SpinnerService ya es dentro del aplicativo
   *
   * @param spinnerService
   * @param bootstrapSpinner
   */
  constructor(
    private spinnerService: SpinnerService,
    private bootstrapSpinner: AppBootstrapSpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retryCount: number = 0;
    let spinnerId: Guid = null;
    req = req.clone();
    return next.handle(req)
      .pipe(
        retryWhen((errors) => {
          let delayTime: number = 750;
          return errors.pipe(
            map((error): HttpErrorResponse => {
              if (error instanceof HttpErrorResponse
                && ((error as HttpErrorResponse).status === 503 || (error as HttpErrorResponse).status === 0)) {
                if (!spinnerId && !this.bootstrapSpinner.available()) {
                  spinnerId = this.spinnerService.showSpinner('Esperando...');
                }
                const httpResponse: HttpErrorResponse = error as HttpErrorResponse;
                if (httpResponse.error && httpResponse.error.commands && httpResponse.error.commands.length > 0) {
                  // Hay un caso especial para el 503 que se usa en el modo mantenimiento
                  // si el response tiene contenido (comandos), no lo tratamos aquí y mandamos al próximo
                  // interceptor
                  throw error;
                }
                return httpResponse;
              } else {
                // Si no es el error específico que esperamos... relanzamos y que lo gestione otro
                throw error;
              }
            }),
            // log error message
            tap(error => {
              retryCount++;
              delayTime = 750 + ((retryCount > 3 ? 3 : retryCount) * 1250 * Math.random());
              // Actualizar el estado...
              if (error.status === 0) {
                if (spinnerId) {
                  this.spinnerService.updateSpinnerText(spinnerId, 'Sin conexión. Reintenando...');
                } else {
                  this.bootstrapSpinner.setText('Sin conexión. Reintenando...');
                }
                return;
              }
              const wsResponse: WebServiceResponse = getInSafe((error), (x) => x.error, null);
              if (wsResponse) {
                let message: string = 'Servicio no disponible...';
                if (wsResponse && wsResponse.result && wsResponse.result.message) {
                  message = wsResponse.result.message;
                }
                if (spinnerId) {
                  this.spinnerService.updateSpinnerText(spinnerId, message);
                } else {
                  this.bootstrapSpinner.setText(message);
                }
                if (wsResponse && wsResponse.result && wsResponse.result.log) {
                  console.debug(wsResponse.result['log']);
                }
              }
            }),
            // restart in 0.5 seconds
            delayWhen(() => interval(delayTime))
          )
        }),
        finalize(
          () => {
            this.spinnerService.removeSpinner(spinnerId);
            spinnerId = null;
          }
        ),
      )
  }
}
