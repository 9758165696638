import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';

import { ClientThemeService } from '../../core/theme-manager/theme.service';
import { KeyValuePair, ThemeDefinitionCompiled } from '../../core/models/ETG_SABENTISpro_Application_Core_models';

@Injectable()
export class ThemeCustomizerService {

  public CurrentTheme: ThemeDefinitionCompiled;
  public SiteIcon: string = './favicon.ico';

  constructor(
    private clientThemeService: ClientThemeService
  ) {
  }

  updatePageTitle(): void {
    let titleElement: any;
    try {
      titleElement = (document && document.querySelector('html > head > title')) ? document.querySelector('html > head > title') as Element : null;
    } catch (err) {
      titleElement = null;
    }

    if (titleElement && this.CurrentTheme.LoginDefinition && this.CurrentTheme.LoginDefinition.Title) {
      titleElement['text'] = this.CurrentTheme.LoginDefinition.Title;
    }
  }

  updatePageFavicon(): Observable<void> {
    return new Observable((obs: Observer<void>) => {
      let iconElement: any;
      try {
        iconElement = (document && document.querySelector('html > head > link[ rel ~= "icon" i]')) ? document.querySelector('html > head > link[ rel ~= "icon" i]') : null;
      } catch (err) {
        iconElement = null;
      }

      const currentTheme: ThemeDefinitionCompiled =
        this.clientThemeService.getCurrentTheme();
      const activeTheme: KeyValuePair<string, string> =
        this.clientThemeService.getActiveThemes()
          .find((theme) => theme.Key.includes(currentTheme.ThemeId));

      if (activeTheme && activeTheme.Value && iconElement && iconElement.hasAttribute('href')) {
        const path: string = activeTheme.Value.substr(0, activeTheme.Value.lastIndexOf('/'));
        iconElement['href'] = `${path}/${currentTheme.ThemeId}.ico`;
      }

      obs.next(null);
      obs.complete();
    })
  }
}
