import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarChartComponent } from './components/progress-bar-chart.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarColorDirective } from './components/progress-bar-color.directive';
import { UtilsModule } from '../../../../../../../../utils/utils.module';


@NgModule({
    declarations: [ProgressBarChartComponent, ProgressBarColorDirective],
    imports: [
        CommonModule,
        MatProgressBarModule,
        UtilsModule
    ]
})
export class ProgressBarChartModule {
}
