import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { FrontendFormElementInput } from '../formelementinput.class';
import * as $ from 'jquery';
import { WindowRef } from '../../../utils/browser-globals';
import {
  FormElementHtmlPlaceholder,
  FormElementHtmlPlaceholderEmbedType
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Este componente no necesita el Control Value Accessor por que es únicamente de salida
 */
@Component({
  selector: 'app-htmlplaceholder',
  templateUrl: './htmlplaceholder.component.html'
})
export class HtmlplaceholderComponent extends FrontendFormElementWrapper implements AfterViewInit {

  FormElementHtmlPlaceholderEmbedType = FormElementHtmlPlaceholderEmbedType;

  @ViewChild('frame') iframe: ElementRef;

  get configuration(): FormElementHtmlPlaceholder {
    return this.config.FormElement as FormElementHtmlPlaceholder;
  }

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  constructor(
    protected formManager: FormManagerService,
    protected sanitizer: DomSanitizer,
    protected cdRef: ChangeDetectorRef,
    @SkipSelf()
    protected cdRefParent: ChangeDetectorRef,
    private windowRef: WindowRef) {
    super(formManager, cdRef, cdRefParent);
  }

  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.configuration.EmbedType === FormElementHtmlPlaceholderEmbedType.Iframe && this.iframe && this.iframe.nativeElement) {
      const frame: HTMLIFrameElement = this.iframe.nativeElement;
      const doc: any = frame.contentWindow.document;
      // Le habilitamos una mini-api al documento hijo
      frame.contentWindow['protractorNavigateByUrl'] = this.windowRef.getNativeWindow().protractorNavigateByUrl;
      // Nos colgamos de algunos eventos...
      $(frame).on('load', function (): void {
        frame.height = String(frame.contentWindow.document.body.scrollHeight);
      });
      // Cargamos el HTML en la ventana
      doc.open();
      doc.write(this.configuration.Html);
      doc.close();
    }
  }
}


