import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

/**
 * Clase para implementar el comportramiento de destrucción de objetos y listeners
 */
// TODO: Add Angular decorator.

@Injectable()
export class DestroyableObjectTrait implements OnDestroy {

  private destroyed: boolean = false;

  public get isDestroyed(): boolean {
    return this.destroyed;
  }

  /**
   * Replay, por si alguien se suscribe después de haber destruido el objeto (dificil pero posible)
   */
  public componentDestroyed$: ReplaySubject<null> = new ReplaySubject<null>(1);

  /**
   *
   */
  ngOnDestroy(): void {
    if (this.destroyed === true) {
      console.error('Destroying already destroyed object');
    }
    this.destroyed = true;
    // Parece imposible... pero a veces pasa que componentDestroyed$ es nulo!
    if (this.componentDestroyed$) {
      this.componentDestroyed$.next(null);
      this.componentDestroyed$.complete();
    }
  }
}
