/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class ContextService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Generate the default context for the user signed in JWT
  *
  * @param {Object} context
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postChangecontext(context: Object, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'context' : JSON.stringify(context)};
    return this.generalService.post('core-context/change-context', params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }
}
