<div *ngIf="messagesError?.length > 0" class="c-simplemesage-container message-error">
  <i ngClass="fa fa-times-circle"></i>
  <ul>
    <li *ngFor="let message of messagesError" [innerHtml]="message.Message"></li>
  </ul>
</div>

<div *ngIf="messagesWarning?.length > 0" class="c-simplemesage-container message-warning">
  <i ngClass="fa fa-exclamation-circle"></i>
  <ul>
    <li *ngFor="let message of messagesWarning" [innerHtml]="message.Message"></li>
  </ul>
</div>

<div *ngIf="messagesSuccess?.length > 0" class="c-simplemesage-container message-success">
  <i ngClass="fa fa-check-circle"></i>
  <ul>
    <li *ngFor="let message of messagesSuccess" [innerHtml]="message.Message"></li>
  </ul>
</div>

<div *ngIf="messagesInfo?.length > 0" class="c-simplemesage-container message-info">
  <i ngClass="fa fa-info-circle"></i>
  <ul>
    <li *ngFor="let message of messagesInfo" [innerHtml]="message.Message"></li>
  </ul>
</div>
