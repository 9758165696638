import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  IViewModeUserConfigurationColumn,
  IViewModeUserConfigurationColumnsBased,
  QueryGroupByMode,
  ViewConfiguration,
  ViewModeTableUserConfigurationColumn,
  ViewResultCellType,
  ViewResultRow,
  ViewUserConfiguration
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { getInSafe } from '../../../../utils/typescript.utils';
import { ListComponent2Service } from '../../../list.service';
import { IViewModeComponent } from '../../i-view-mode.component';
import { GridRowMode } from '../shared/grid-row-mode';
import { IGridRowMode } from '../shared/grid-row-mode-interface.component';
import { GridRowDefaultComponent } from './grid-row-default.component';
import { ViewModeUtils } from '../../viewmode.utils';
import { filter, takeUntil } from 'rxjs/operators';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { ActiveUserConfigurationClass } from '../../../activeUserConfigurationClass';

/**
 * This component will receive the customized configuration of the list by
 * input, and will generate by output the same configuration but with the
 * values modified by the user.
 */
@Component({
  selector: 'app-view-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [ChangedetectorReference],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  animations: fuseAnimations
})
export class ViewGridComponent
  extends DestroyableObjectTrait
  implements OnInit, OnChanges, OnDestroy, IViewModeComponent {

  /**
   * Input `ViewResultRow[]` object to display on the component.
   */
  @Input() data: ViewResultRow[];

  /**
   *
   */
  @Input() classes: string;

  /**
   * Container Template Reference.
   */
  @ViewChild('gridcontainer', {read: ViewContainerRef, static: true}) container: ViewContainerRef;

  /**
   * Rendered row component.
   */
  component: ComponentRef<IGridRowMode>;

  /**
   * Fields on headers.
   */
  headerFields: IViewModeUserConfigurationColumn[];

  /**
   * Import of `isNullOrUndefined`.
   */
  isNullOrUndefined = isNullOrUndefined;

  /**
   * Import of `ViewResultCellType`.
   *
   * @type {ViewResultCellType}
   */
  ViewResultCellType: any = ViewResultCellType;

  /**
   * True when data is being loaded
   */
  dataIsLoading: boolean;

  /**
   * Get an instance of GridComponent
   *
   * @param {ListComponent2Service} listComponentService Instance of the list
   *  component service shared with the component.
   */
  constructor(
    public listComponentService: ListComponent2Service,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangedetectorReference) {
    super();
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties.
   */
  ngOnInit(): void {

    // Initializes component suscription to view `userConfigurationChanged`.
    this.listComponentService
      .activeUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((i) => !!i)
      )
      .takeUntil(this.componentDestroyed$)
      .subscribe((next: ActiveUserConfigurationClass) => {
        this.updateHeader(next.userConfiguration);
        this.createGridRowIteratorComponent();
      });

    this.listComponentService
      .requestInProgress$
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((i) => {
        if (this.dataIsLoading !== i) {
          this.dataIsLoading = i;
          this.changeDetector.changeDetector.detectChanges();
          this.changeDetector.changeDetectorParent.detectChanges();
        }
      });
  }

  /**
   * A lifecycle hook that is called when any data-bound property of a
   * directive changes.
   */
  ngOnChanges(): void {
  }

  initialize(): void {
  }

  /**
   * This method update the `headerFields` property from the `ViewUserConfiguration`
   * obtained from the list service.
   */
  private updateHeader(userConfig: ViewUserConfiguration): void {
    this.headerFields = (userConfig.CurrentViewMode as IViewModeUserConfigurationColumnsBased)
      .Columns
      .filter((x: ViewModeTableUserConfigurationColumn) => x.Visible === true);
  }

  /**
   * If the list has results
   */
  listHasResults(): boolean {
    return this.data && this.data.length > 0;
  }

  /**
   * Texto para cuando no hay resultados
   */
  getNoResultText(): string {
    return getInSafe(this.listComponentService.getConfiguration(), c => c.NoResultText, null);
  }

  /**
   * Returns a valid `QueryGroupByMode` for the current group mode.
   */
  getGroupMode(): QueryGroupByMode {
    const config: ViewConfiguration =
      this.listComponentService.getConfiguration();

    return getInSafe(config,
      c => c.GroupBy.ExpandGroupMode, QueryGroupByMode.Default);
  }

  /**
   * Creates the grid row component iterator.
   */
  createGridRowIteratorComponent(): void {
    if (this.container) {
      this.container.clear();
    }

    let baseComponent: Type<IGridRowMode> = GridRowDefaultComponent;

    if (!isNullOrUndefined(GridRowMode[this.getGroupMode()])) {
      baseComponent = GridRowMode[this.getGroupMode()];
    }

    this.component = this.container.createComponent(baseComponent);
    this.component.instance.headerFields = this.headerFields;
    this.component.instance.initialize();
  }

  /**
   * Clases CSS para las  cabeceras de los campos
   *
   * @param headerColumnName
   */
  resolveTableHeaderCellClasses(header: IViewModeUserConfigurationColumn): string[] {
    return ViewModeUtils.resolveTableHeaderCellClasses(header, this.listComponentService);
  }

  /**
   * Returns the length for full colspan.
   */
  fullColspanLength(): number {
    return !this.headerFields ? 0 : this.headerFields.length;
  }
}
