import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  BatchState,
  IVboOperation,
  ViewConfiguration,
  ViewsOperationWithForm,
  ViewsPluginRequest,
  ViewUserConfiguration
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AbstractDecoupledModalComponent } from '../../../decoupled-modal/models/abstract-decoupled-modal.component';
import { getInSafe } from '../../../utils/typescript.utils';
import { CommandService } from '../../../../core/commands/command.service';
import { CORE_QUEUE } from '../../../../core/models/ETG_SABENTISpro_Models_models';


/**
 * To do: Pending to change when modals service will be full operative.
 */
@Component({
  selector: 'app-view-operation-form-modal',
  templateUrl: './view-operation-form-modal.component.html'
})
export class ViewOperationFormModalComponent
  extends AbstractDecoupledModalComponent implements OnChanges, OnInit {

  /**
   * View configuration
   */
  @Input() configuration: ViewConfiguration;

  /**
   * User view configuration
   */
  @Input() userConfiguration: ViewUserConfiguration;

  /**
   * Operation of export.
   */
  @Input() operation: ViewsOperationWithForm;

  /**
   *
   */
  @Input() onVboOperationCompleted: EventEmitter<{ operation: IVboOperation, result: { id: string, message: any[], responseData: any } | CORE_QUEUE }>;

  /**
   * Form params
   */
  formParams: {
    pluginRequest: ViewsPluginRequest,
    currentUserConfiguration: ViewUserConfiguration,
    operation: IVboOperation,
    isModal: boolean
  }

  /**
   * Task to excecute.
   */
  task: CORE_QUEUE;

  constructor(private commandService: CommandService) {
    super();
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if (this.task && [BatchState.CANCELED, BatchState.ERROR, BatchState.COMPLETED].includes(this.task.status)) {
      this.task = null;
    }
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.formParams = {
      isModal: true,
      pluginRequest: this.configuration.PluginRequest,
      currentUserConfiguration: this.userConfiguration,
      operation: this.operation,
    };
    if (this.operation.FormArguments) {
      Object.assign(this.formParams, this.operation.FormArguments);
    }
  }

  /**
   * Close the Form PopUp
   */
  public saveSucceed(e: { id: string, message: any[], responseData: any }): void {
    // No es lo más amigable, pero el hecho de que lancemos o no un batch en el proceso
    // depende de la existencia de este objeto en el valor de retorno del formulario.
    // TODO: Debería ser algo implíticamente configurado en la operación
    const task: CORE_QUEUE = getInSafe(e, (i) => i.responseData.Result.task as CORE_QUEUE, null);
    if (task != null) {
      this.task = e.responseData.Result.task as CORE_QUEUE;
    } else {
      if (this.operation.OnFinishCommands) {
        this.commandService.executeCommandChain(Object.values(this.operation.OnFinishCommands)).then();
      }
      this.closeModal();
      if (this.onVboOperationCompleted) {
        this.onVboOperationCompleted.emit({operation: this.operation, result: e})
      }
    }
  }

  public dispatchEventOnTaskCompleted(queue: CORE_QUEUE): void {
    if (this.onVboOperationCompleted) {
      this.onVboOperationCompleted.emit({operation: this.operation, result: this.task})
    }
  }
}
