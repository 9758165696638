/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class FileService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Esto es un endpoint comodín que usamos en frontend para, conocido un contexto y un ID, generar un fileref que pueda ser consumido para descargar los ficheros de una instancia de contexto determinada.  Marco como OBSOLETE este endpoint a fecha 21/09/2021, porque para mejorar la seguridad de este endpoint habría que recibir en lugar de un GUID un CoreHashedKey firmado o bien algo análogo.
  *
  * @param {string} context
  * @param {string} id
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @deprecated Por motivos de seguridad debe generar el fileRef en backend, o bien hacer un método análogo que trabaje con un CoreHashedKey
  * @returns {string}
  */
  getFilefromcontextandid(context: string, id: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    const params: any = {'context' : context, 'id' : id};
    return this.generalService.get('core-files/fileFromContextAndId', params, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }
}
