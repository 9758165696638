import { Directive, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { getInSafe, isNullOrUndefined } from '../../../../../../../shared/utils/typescript.utils';
import { PrimeUtils } from '../../../../../../../shared/utils/prime.utils';
import { NGXChartComponent } from './ngx-chart.class';
import {
  BubbleChartDisplayOptions,
  ILegendChartDisplayOption,
  ISerie,
  ISerieValue,
  NGXChartDisplayOptions,
  PieChart,
  PieChartDisplayOptions,
  SerieValue,
  SimpleHorizontalBarChart,
  SimpleVerticalBarChart
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive()
export abstract class NGXSimpleSeriesChartComponent<TChart extends SimpleHorizontalBarChart | SimpleVerticalBarChart | PieChart,
  TDisplayOptions extends NGXChartDisplayOptions | PieChartDisplayOptions | BubbleChartDisplayOptions>
  extends NGXChartComponent<TChart, TDisplayOptions> implements OnInit {

  /**
   * Data to display.
   */
  data: { name: string, series?: { name: string, value: number }[] }[];

  /**
   * Color Scheme
   */
  colorScheme: { domain: string[] }

  /**
   * VerticalBarChartComponent class constructor.
   */
  protected constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
    this.data = [];
  }

  /**
   * Component initialization lifecycle method.
   */
  ngOnInit(): void {
    this.chart.update();
  }

  getTooltipText(item: any): string {
    if (this.displayOptions && this.displayOptions.TooltipText && item) {
      return this.displayOptions.TooltipText.replace('{value}', item.value);
    }
    return '';
  }

  /**
   * Initialization method.
   */
  public initializeChart(): void {
    this.colorScheme = getInSafe(this.displayOptions, x => {
      return {domain: x.ColorScheme}
    }, {domain: []});

    const valueSerie: ISerie = this.getDataSeries().Series[this.currentChart.ValueSeriesId];
    const labelSerie: ISerie = this.getDataSeries().Series[this.currentChart.LabelSeriesId];

    const valueSerieValues: ISerieValue[] = PrimeUtils.ParseKeyItemToArray<SerieValue>(getInSafe(valueSerie, (s: ISerie) => s.Values, {} as any));
    const labelSerieValues: ISerieValue[] = PrimeUtils.ParseKeyItemToArray<SerieValue>(getInSafe(labelSerie, (s: ISerie) => s.Values, {} as any));

    const auxData: { name: string, value: number }[] = [];
    valueSerieValues.map((v, i) => {
      auxData.push({
        // Si una etiqueta viene como nula, nos rompe el gráfico!
        name: isNullOrUndefined(labelSerieValues[i].Value) ? 'N/D' : labelSerieValues[i].Value.toString(),
        value: valueSerieValues[i].Value.toString()
      });
    });
    this.numSeries = valueSerieValues !== null && valueSerieValues !== undefined ? valueSerieValues.length : 0;
    this.view = this.GetViewValueOnInitializeChart()
    this.data = auxData;
    this.chart.update();
    this.cdReference.changeDetector.detectChanges();
  }

  onNgxChartResized(event: any): void {
    this.view = this.GetViewValueOnResize();
    this.cdReference.changeDetectorParent.detectChanges();
  }

  onNgxChartResizedWithDirective(): void {
    this.view = this.GetViewValueOnResize();
    this.cdReference.changeDetectorParent.detectChanges();
  }

  public abstract GetViewValueOnInitializeChart(): [number, number];

  public abstract GetViewValueOnResize(): [number, number];

  /**
   * Custom responsive management
   */
  public getLegendDisplayOptions(): ILegendChartDisplayOption {
    if (!this.displayOptions) {
      return null;
    }
    const value: NGXChartDisplayOptions = Object.assign(new NGXChartDisplayOptions(), this.displayOptions);
    if (window.innerWidth < 600) {
      value.LegendPosition = 'bottom';
    }

    return value;
  }
}
