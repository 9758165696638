import { isArray, isNullOrUndefined, isNullOrWhitespace, UtilsTypescript } from '../../shared/utils/typescript.utils';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';
import { MenuItemCompiled, MenuType } from '../models/ETG_SABENTISpro_Application_Core_models';

export class Navutils {

  /**
   * Finds the original node when the current node is of type Anchor
   * @param item
   * @param controller
   */
  public static findOriginalNode(item: MenuItemCompiledFrontend, controller: string): MenuItemCompiledFrontend {
    if (item.controller === controller && item.menuType !== MenuType.Anchor) {
      return item;
    } else {
      if (item.children && item.children.length > 0) {
        const nodes: MenuItemCompiledFrontend[] = item.children
          .reduce((result: MenuItemCompiledFrontend[], menuItem: MenuItemCompiledFrontend) => {
            return [...result, Navutils.findOriginalNode(menuItem, controller)];
          }, [])

        const res: MenuItemCompiledFrontend = nodes.find(node => !!node);
        return res;
      } else {
        return null;
      }
    }
  }

  /**
   * Check if a path starts with another path
   *
   * (home/eo/tests, home/eo) -> true
   * (home/eo/tests, home/e) -> false
   *
   * @param path
   * @param startsWith
   */
  public static pathStartsWith(path: string[], startsWith: string[]): boolean {
    // Clear any preceding empty spaces from both arrays
    for (let x: number = 0; x < startsWith.length; x++) {
      if (x === path.length) {
        return false;
      }
      if (startsWith[x] !== path[x]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Check if a path contains a segment
   * @param path
   * @param segment
   */
  public static pathContains(path: string, segment: string): boolean {
    return !!path.split('/').find((i) => i === segment);
  }

  /**
   * Devuelve la ruta, con parámetors reemplazados, a partir de un snapshot de angular
   *
   * @param {ActivatedRouteSnapshot} ars
   * @returns {Object[]}
   */
  public static findExpandedUrlFromRoute(ars: ActivatedRouteSnapshot): string {
    let route: string = this.findParameterizedUrlFromRoute(ars);
    const args: { [id: string]: string } = this.findParamsFromRouteNew(ars);
    for (const argumentName of Object.keys(args)) {
      route = route.replace(argumentName, args[argumentName]);
    }
    return route;
  }

  /**
   * Devuelve una ruta, sin parámetros reemplazados, a partir de un shapshot de angular
   *
   * @param ars
   */
  public static findParameterizedUrlFromRoute(ars: ActivatedRouteSnapshot): string {

    const routerSnapshot: RouterStateSnapshot = ars['_routerState'] as RouterStateSnapshot;
    if (routerSnapshot.url.indexOf('//') !== -1) {
      throw new Error(`No se puede cargar la navegación de la ruta '${routerSnapshot.url}' por que le faltan argumentos`);
    }

    let urlParts: string[] = [];
    let parent: ActivatedRouteSnapshot = ars;
    const invertedRoutes: ActivatedRouteSnapshot[] = [];
    const usedParams: string[] = [];

    while (parent) {
      invertedRoutes.push(parent);
      parent = parent.parent;
    }

    for (let j: number = invertedRoutes.length - 1; j >= 0; j--) {
      const parentUrl: string[] = UtilsTypescript.jsonClone(invertedRoutes[j].url.map((i) => i.path));
      // Este caso es para las redirecciones de rutas que no tienen controlador ni path (rutas que no existen de verdad)
      for (const paramKey of Object.keys(invertedRoutes[j].params)) {
        for (let i: number = 0; i < parentUrl.length; i++) {
          if (isNullOrWhitespace(invertedRoutes[j].params[paramKey]) || usedParams.includes(paramKey)) {
            continue;
          }
          parentUrl[i] = parentUrl[i].replace(invertedRoutes[j].params[paramKey], '%' + paramKey);
        }
        usedParams.push(paramKey);
      }
      urlParts = [...urlParts, ...parentUrl];
    }

    return '/' + urlParts.join('/');
  }

  /**
   * Extrae los parámetros de la rut actual
   *
   * @param ars
   */
  public static findParamsFromRouteNew(ars: ActivatedRouteSnapshot): { [id: string]: string } {
    let parent: ActivatedRouteSnapshot = ars;
    const params: { [id: string]: string } = {};

    while (parent) {
      for (const paramKey of Object.keys(parent.params)) {
        params['%' + paramKey] = parent.params[paramKey];
      }
      parent = parent.parent;
    }

    return params;
  }

  /**
   * flatten
   * @param {TreeviewItem} node
   * @param {TreeviewItem[]} collection
   */
  public static flatten(node: MenuItemCompiled, collection: MenuItemCompiled[], controller: string): void {
    if (isNullOrUndefined(node)) {
      return;
    }
    collection.push(node);
    if (isNullOrUndefined(node.children) || node.children.length === 0) {
      return;
    }
    node.children.forEach(x => {
      if (Navutils.hasController(x, controller)) {
        this.flatten(x, collection, controller)
      }
    });
  }

  /**
   * Return if a tree has a given controller
   * @param {MenuItemCompiled} tree
   * @param {string} controller
   * @returns {boolean}
   */
  public static hasController(tree: MenuItemCompiled, controller: string):
    boolean {
    if (tree.controller === controller) {
      return true;
    } else if (!isNullOrUndefined(tree.children) && tree.children.length > 0) {
      for (let i: number = 0; i < tree.children.length; ++i) {
        const result: boolean = Navutils.hasController(tree.children[i], controller);
        if (result) {
          return result;
        }
      }
    } else {
      return false;
    }
  }

  /**
   * finds the path (array of MenuItemCompiled items) to the node with the expanded baackend path calculated
   * when building the request.
   *
   * @param {MenuItemCompiled} tree: given tree to search in
   * @param {string} nodeController: controller name we are looking for
   * @param args
   * @returns {MenuItemCompiled[]}: path found
   */
  public static findPathToNode(tree: MenuItemCompiledFrontend, backendExpandedPath: string, args: Object = null): MenuItemCompiledFrontend[] {

    const result: MenuItemCompiledFrontend[] = [];
    let children: MenuItemCompiledFrontend[] = tree.children;

    const requiredLength: number = backendExpandedPath.split('/').length;

    while (children && children.length) {

      // Buscamos el mejor match
      const matchingChild: MenuItemCompiledFrontend = children
        .filter((i) => this.pathStartsWith(backendExpandedPath.split('/'), i.expandedBackendPathExploded))
        // Excluimos los tipo anchor, ya que no forman parte de la cadena real de navegación!
        .filter((i) => i.menuType !== MenuType.Anchor)
        .sort((i, j) => j.expandedBackendPath.length - i.expandedBackendPath.length)
        .find(() => true);

      if (!matchingChild) {
        if (result.length < requiredLength) {
          const availableChildrenBackendPaths: string = Array.from(children.map((i) => i.expandedBackendPath)).join(', ');
          console.error('Could not find current path ' + backendExpandedPath + ' in available navigation tree: ' + availableChildrenBackendPaths);
        }
        break;
      }

      result.push(matchingChild);

      children = matchingChild.children;
    }

    return result.reverse();
  }

  /**
   * Dada un path de backend, reemplaza los argumentos.
   *
   * structure/company/%company/detail -> structure/company/123642/detail
   *
   * @param backendPath
   * @param params
   */
  public static replaceBackendArguments(backendPath: string, params: { [id: string]: string }): string {
    if (UtilsTypescript.isNullOrWhitespace(backendPath)) {
      return null;
    }
    for (const p of Object.keys(params)) {
      backendPath = backendPath.replace(p, params[p]);
    }
    return backendPath;
  }

  /**
   * Busca en una jerarquía el primer path válido para navegar, se usa
   * para asignar navegación a elementos "huecos" (tab containsers, etc.)
   * @param item
   */
  public static findFirstAvailableNavigationPath(item: MenuItemCompiledFrontend): string {
    if (isNullOrUndefined(item.children)) {
      return null;
    }
    const validChild: MenuItemCompiledFrontend = item.children.find((x) => {
      return !isNullOrUndefined(x.frontendPath) && !x.accessBlocked()
    });
    if (!isNullOrUndefined(validChild)) {
      return validChild.frontendPath;
    }
    for (const child of item.children) {
      const childPath: string = Navutils.findFirstAvailableNavigationPath(child);
      if (!isNullOrUndefined(childPath)) {
        return childPath;
      }
    }
    return null;
  }

  /**
   * Returns a boolean indicating if the menuItem argument is a parametric node.
   * @param menuItem
   */
  public static isParametricPath(menuItem: MenuItemCompiled): boolean {
    return isArray(menuItem.arguments) && menuItem.arguments.length > 0;
  }

  /**
   * El router devuelve al AR de más alto nivel, pero para la gestión de los paths necesitamos el de más abajo
   *
   * @param ar
   */
  public static findLastActivatedRouteSnapshotFromRoute(ar: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    let route: ActivatedRouteSnapshot = ar;
    let children: ActivatedRouteSnapshot[] = ar.children;
    while (children && children.length > 0) {
      route = children[0];
      children = route.children;
    }
    return route;
  }
}


