<div style="border: 1px solid #cccccc; text-align: center; padding: 10px; display: flex; flex-direction: column">
  <!-- Carga de archivo -->
  <app-file-dropable
      *ngIf="canUploadFiles()"
      [uniqueId]="uniqueComponentId('fileinput-')"
      (onFilesLoaded)="uploadFiles($event)"
      (onOverError)="onOverError($event)"
      [multiple]="isMultiple()"
      [validExtensions]="this.config.validExtensions"
      [buttonLabel]="this.formElement.ButtonLabel"
      [enableDragAndDrop]="this.formElement.EnableDragAndDrop"
      [validSize]="this.config.validSize"
      [maxFiles]="this.config.maxFiles"
      [currentFiles]="files?.length"
      [disabled]="isDisabled">
  </app-file-dropable>
  <!-- Resultado -->
  <ng-container *ngTemplateOutlet="results"></ng-container>
  <ng-container *ngTemplateOutlet="validations"></ng-container>
</div>

<ng-template #results>
  <table class="table" *ngIf="files?.length > 0">
    <thead>
    <tr>
      <th width="50%">{{ "Nombre" | t }}</th>
      <th>{{ "Tamaño" | t }}</th>
      <th>{{ "Progreso" | t }}</th>
      <th>{{ "Estado" | t }}</th>
      <th>{{ "Acciones" | t }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let file of files">
      <td>
        <ng-container *ngIf="file?.UploadedFile;then linkWhenUploaded else linkWhileUploading"></ng-container>
        <ng-template #linkWhenUploaded>
          <a target="_blank" href="{{ getLink(file?.UploadedFile) }}">{{ file?.name }}</a>
        </ng-template>
        <ng-template #linkWhileUploading>
          <span>{{file?.name}}</span>
        </ng-template>
      </td>
      <td>{{ formatFileSize(file?.size) }}</td>
      <td>
        <div class="progress" style="margin-bottom: 0;">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': file?.progress  + '%' }"></div>
        </div>
      </td>
      <td>
        <div *ngIf="file.getStatus() == uploadStatus.WAITING" class="upload-status">
          <span>{{ 'Pendiente' | t }}</span>
        </div>
        <div *ngIf="file.getStatus() == uploadStatus.PAUSED" class="upload-status">
          <span>{{ 'En pausa' | t }}</span>
        </div>
        <div *ngIf="file.getStatus() == uploadStatus.CANCELED" class="upload-status">
          <span>{{ 'Cancelado' | t }}</span>
        </div>
        <div *ngIf="file.getStatus() == uploadStatus.STARTED" class="upload-status">
          <span>{{ 'Cargando' | t }}</span>
        </div>
        <div *ngIf="file.getStatus() == uploadStatus.COMPLETED" class="upload-status">
          <span>{{ 'Completado' | t }}</span>
        </div>
        <div *ngIf="file.getStatus() == uploadStatus.ERROR" class="upload-status">
          <span> {{ 'Error' | t }}</span>
        </div>
      </td>
      <td nowrap>
        <div *ngIf="!isDisabled">
          <strong class="upload-action upload"
                  *ngIf="[uploadStatus.PAUSED, uploadStatus.WAITING].includes(file.getStatus())"
                  (click)="file.upload()">
            <i class="glyphicon glyphicon-arrow-up"></i>
            <span> {{ "Iniciar carga" | t }}</span>
          </strong>
          <strong class="upload-action cancel"
                  *ngIf="[uploadStatus.STARTED, uploadStatus.PAUSED].includes(file.getStatus())" (click)="cancel(file)">
            <i class="glyphicon glyphicon-stop"></i>
            <span> {{ "Cancelar" | t }}</span>
          </strong>
          <strong class="upload-action remove" (click)="removeFile(file)"
                  *ngIf="[uploadStatus.COMPLETED, uploadStatus.CANCELED, uploadStatus.ERROR, uploadStatus.WAITING].includes(file.getStatus())">
            <i class="fas fa-times-circle"></i>
            <span> {{ "Eliminar" | t }}</span>
          </strong>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #validations>
  <div class="control-validations" [ngClass]="{ 'over-drop-error': overError }">
    <small *ngIf="config.validSize">
      {{ ('El tamaño máximo por archivo es de @filesize' | t: {'@filesize': formatFileSize(config?.validSize)})  }}
    </small>
    <br>
    <small *ngIf="config.validExtensions?.length > 0">
      {{('Las extensiones válidas de archivo son: @extensions ' | t :{'@extensions': validExtensions()}) }}.
    </small>
    <br>
    <small *ngIf="config.maxFiles > 1">
      {{ 'Puedes agregar hasta @filecount archivo/s más.' | t :{'@filecount': (config.maxFiles - files.length)} }}
    </small>
  </div>
</ng-template>
