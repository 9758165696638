import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { FormElementTextArea } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined, UtilsTypescript } from '../../../../utils/typescript.utils';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { MaxLengthValidationInterface } from '../../shared-interfaces';
import { lengthValidationClasses, getRemainingChars, handleMaxLengthInput } from '../../shared-functions';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.style.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTextareaComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputTextareaComponent), multi: true}
  ]
})
export class InputTextareaComponent
  extends FrontendFormElementInput
  implements MaxLengthValidationInterface {

  protected valueValue: string;

  isNullOrUndefined: any = isNullOrUndefined;

  /**
   * Form element definition
   */
  get elementConfiguration(): FormElementTextArea {
    return this.formManagerService.getConfigFromSelector(this.config.ClientPath) as FormElementTextArea;
  }

  /**
   * Configuration for the rich text editor
   */
  get richTextEditorConfiguration(): AngularEditorConfig {
    const config: AngularEditorConfig = {} as AngularEditorConfig;

    config.showToolbar = this.elementConfiguration.RichTextConfiguration.ShowToolbar;
    config.editable = true;
    config.spellcheck = false;
    config.height = 'auto';
    config.minHeight = '40';
    config.maxHeight = '200';
    config.width = 'auto';
    config.translate = 'no';
    config.enableToolbar = true;
    config.showToolbar = true;
    config.placeholder = 'Escriba aquí...';
    config.defaultParagraphSeparator = '';
    config.defaultFontName = '';
    config.defaultFontSize = '1';
    config.toolbarPosition = 'top';

    if (this.config.rows > 0 && isNullOrUndefined(this.elementConfiguration.RichTextConfiguration.Height) || this.elementConfiguration.RichTextConfiguration.Height === 'auto') {
      config.minHeight = String(this.config.rows * 20);
    }

    return config;
  }

  set value(value: string) {
    this.valueValue = this.normalizeValue(value);
    this.propagateChange(value, true);
  }

  get value(): string {
    return this.valueValue;
  }

  writeValue(obj: any): void {
    this.valueValue = this.normalizeValue(obj);
  }

  /**
   * Ensure that whitespaces are trated as null values
   *
   * @param value
   */
  protected normalizeValue(value: string): string {
    if (UtilsTypescript.isNullOrWhitespace(value)) {
      return value;
    }
    return value;
  }

  /**
   * Returns a boolean indicating if the user input must be "denied"
   * once the input length count equals the max length.
   */
  handleMaxLengthInput(): boolean {
    if (handleMaxLengthInput(this.config, this.value)) {
      return true;
    }

    return false;
  }

  /**
   * @inheritdoc
   */
  lengthValidationClasses(): Record<string, boolean> {
    return lengthValidationClasses(this.valueValue, this);
  }


  /**
   * @inheritdoc
   */
  getRemainingChars(): string {
    return getRemainingChars(this.valueValue, this);
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    const inputElement: HTMLElement = document.getElementById(this.uniqueComponentId('textarea-'));

    if (inputElement) {
      inputElement.focus();
      return true;
    }

    return false;
  }
}
