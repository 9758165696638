import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseChartDirective } from './fuse-chart.directive';
import { MapChartModule } from './map-chart/map-chart.module';
import { PieChartModule } from './pie-chart/pie-chart.module';
import { RadarChartModule } from './radar-chart/radar-chart.module';
import { ProgressBarChartModule } from './progress-bar-chart/progress-bar-chart.module';
import { FuseLineChartModule } from './line-chart/fuse-line-chart.module';
import { FuseAreaChartModule } from './area-chart/fuse-area-chart.module';
import { SingleNumberChartModule } from './single-number-chart/single-number-chart.module';
import { GroupedHorizontalBarChartModule } from './bar-chart/grouped-horizontal-bar-chart/grouped-horizontal-bar-chart.module';
import { GroupedVerticalBarChartModule } from './bar-chart/grouped-vertical-bar-chart/grouped-vertical-bar-chart.module';
import { SimpleVerticalBarChartModule } from './bar-chart/simple-vertical-bar-chart/simple-vertical-bar-chart.module';
import { SimpleHorizontalBarChartModule } from './bar-chart/simple-horizontal-bar-chart/simple-horizontal-bar-chart.module';
import { CustomDispersionChartModule } from './custom-dispersion-chart/custom-dispersion-chart.module';
import { FuseBubbleChartModule } from './bubble-chart/fuse-bubble-chart.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    FuseChartDirective,
  ],
  exports: [
    FuseChartDirective,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    GroupedHorizontalBarChartModule,
    GroupedVerticalBarChartModule,
    FuseLineChartModule,
    FuseBubbleChartModule,
    MapChartModule,
    MatIconModule,
    PieChartModule,
    RadarChartModule,
    CustomDispersionChartModule,
    ProgressBarChartModule,
    FuseAreaChartModule,
    SingleNumberChartModule,
    SimpleVerticalBarChartModule,
    SimpleHorizontalBarChartModule
  ]
})
export class ChartTypesModule {
}
