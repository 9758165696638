import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import * as jquery from 'jquery';

/**
 * Esta directiva sirve para convertir en enlaces
 * aquellos enlaces que estén como texto.
 */
@Directive({selector: '[appUrlify]'})
export class UrlifyDirective implements OnInit {

  /**
   * Para activar o desactivar el comportamiento
   */
  @Input() appUrlify: boolean;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {

    if (this.appUrlify !== true) {
      return;
    }

    setTimeout(() => { // wait for DOM rendering
      this.urliFy();
    });
  }

  urliFy(): void {

    /*
    * TODO: El enfoque de este proceso es muy agresivo, ya que destruiría elementos <A> existentes en el componente que se quiere tratar.
    * En realidad habría que recorrer los elementos individuales del DOM, y únicamente procesar aquellos de tipologías específicas como
    * div, span, p, o bien procesar todos menos los tipo <a>
    * */
    const urlRegex: RegExp = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    const html: string = jquery(this.elementRef.nativeElement).html();
    let myArray: RegExpExecArray;
    let htmlResult: string = '';
    let lastPos: number = 0;
    while ((myArray = urlRegex.exec(html)) !== null) {
      const link: string = '<a target="_blank" href="' + myArray[0] + '">' + myArray[0] + '</a>';
      htmlResult = htmlResult + html.substr(lastPos, urlRegex.lastIndex - myArray[0].length - lastPos) + link;
      lastPos = urlRegex.lastIndex;
    }
    htmlResult = htmlResult + html.substr(lastPos, html.length - lastPos);
    jquery(this.elementRef.nativeElement).html(htmlResult);
  }
}
