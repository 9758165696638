import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationService } from './navigation.service';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para propagar los cambios en la navegación al título de página HTML. Se implementa porque
 * ayuda en la propagación de informacíon de ubicación a Google Analytics
 */
export class PagetitleService {

  private defaulTitle: string = null;

  constructor(
    private titleService: Title,
    private navigationService: NavigationService
  ) {
    this.defaulTitle = titleService.getTitle();
    this.navigationService.currentNavigationRequest
      .pipe(
        filter((i) => i !== null),
        map((i) => {
          if (i && i.responseNodeForTitle && i.responseNodeForTitle.title) {
            titleService.setTitle(i.responseNodeForTitle?.title);
          } else {
            titleService.setTitle(this.defaulTitle);
          }
        })
      ).subscribe();
  }
}
