import { Injectable } from '@angular/core';
import { ClientThemeService } from '../theme-manager/theme.service';
import { ILayout, ThemeDefinitionCompiled } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ILayoutVariablesInterface } from './interface/i-layout-variables.interface';
import { LayoutVariables } from './constants/layout-variables.class';
import { isNullOrUndefined } from '../../shared/utils/typescript.utils';

@Injectable()
export class LayoutManagerService {

  constructor(
    private themeService: ClientThemeService,
  ) {
  }

  getCurrentLayoutName(): string {
    const currentTheme: ThemeDefinitionCompiled = this.themeService.getCurrentTheme();
    const currentLayout: string = currentTheme.LayoutDefinition.CurrentLayout;

    if (!isNullOrUndefined(currentLayout) && !LayoutVariables[currentLayout]) {
      const existingTpyes: string = Object.keys(currentLayout).join(', ');
      throw new Error(
        `'El tipo de layout especificado (${currentLayout}) no existe.
        Layouts soportados: ${existingTpyes}`
      );
    }
    return currentLayout;
  }

  getCurrentLayoutDefinition(): ILayout {
    const currentTheme: ThemeDefinitionCompiled = this.themeService.getCurrentTheme();
    const currentLayout: string = this.getCurrentLayoutName();
    return currentTheme.LayoutDefinition.AvailableLayouts[currentLayout];
  }

  getCurrentLayoutVariables(): ILayoutVariablesInterface {
    const currentLayout: string = this.getCurrentLayoutName();
    const layoutVariable: ILayoutVariablesInterface = LayoutVariables[currentLayout];

    return layoutVariable;
  }

}
