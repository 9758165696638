<div class="batch-item__container">
  <div class="item-title" [class.item-mapping]="task?.ShowBar">
    <div>{{ task?.Title }}</div>
    <div class="item-identifier" *ngIf="false || task?.ShowIdentifier">
      <small>{{ task.QueueId }} | {{ task.Job.plugin }} </small>
    </div>
  </div>
  <div class="progress" *ngIf="task?.ShowBar && (isRunning() || isPaused())">
    <div [ngClass]="getBarDynamicStyle()" class="progress-bar progress-bar-striped active" role="progressbar"
         [ngStyle]="getTaskPercentageStyle()">
      <span class="sr-only">{{ task?.CurrentExecution.Progress }}% Completado</span>
      {{ '@progress% Completado' | t:{'@progress': task?.CurrentExecution.Progress} }}
    </div>
  </div>
  <div class="informacion" *ngIf="!isRunning()">
    <div class="data" *ngIf="task?.ShowInfo">
      <div class="flex-col-xs-12 flex-col-sm-6">
        <span>{{ 'Última creación:' | t}} </span>
        <span>{{ created() }}</span>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-6">
        <span>{{ 'Última ejecución:' | t}} </span>
        <span>{{ lastRun() }}</span>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-6">
        <span>{{ 'Estado:' | t }} </span>
        <span>{{ getStatus() | t }}</span>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-6">
        <span>{{ 'Número de ejecuciones:' | t }} </span>
        <span>{{ task?.CurrentExecution?.BatchCount }}</span>
      </div>
    </div>
    <div class="results">
      <div class="flex-col-xs-12">
        <p *ngIf="task?.HasResult && isStatusError">
          {{ 'Se ha producido un error en la ejecución del proceso.' | t }}
        </p>
        <em *ngIf="task?.ShowInfo">{{ task?.CurrentExecution?.ProgressMessage }}</em>
      </div>
    </div>
  </div>

  <div class="informacion" *ngIf="isRunning()">
    <div class="data running">
      <div>
        <div class="flex-col-xs-12">
          <em>{{ task?.CurrentExecution?.ProgressMessage }}</em>
        </div>
        <div class="flex-col-xs-12 flex-col-sm-6">
          {{ 'Iniciado' | t }}:
          <em>{{started()}}</em>
        </div>
        <div class="flex-col-xs-12 flex-col-sm-6">
          <span>{{ 'Estado' | t }}: </span>
          <span>{{ getStatus() | t }}</span>
        </div>
        <div class="flex-col-xs-12 flex-col-sm-6">
          {{ 'Tiempo en ejecución' | t }}:
          <em>{{ runningTime() | t }}</em>
        </div>
        <div class="flex-col-xs-12 flex-col-sm-6">
          <span>{{ 'Número de ejecuciones' | t }}: </span>
          <span>{{ task?.CurrentExecution?.BatchCount }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="controls flex-col-xs-12 flex-col-sm-12">
    <button id="startButton" class="o-btn__accent" *ngIf="isRunnable()" (click)="start()">{{ 'Ejecutar ahora' | t }}
    </button>

    <ng-container *ngIf="task?.HasResult && isStatusCompleted && !isRunning()">
      <button *ngFor="let button of currentResult" [ngClass]="button.Classes"
              (click)="button.OnClickFunction()">
        <i [ngClass]="button.Icon"></i> {{ button.Title }}
      </button>
    </ng-container>

    <button class="o-btn__secondary" *ngIf="task?.Pausable && (isRunning() || isPaused())"
            (click)="togglePause()">{{ isPaused() ? ('Continuar' | t) : ('Pausar' | t)}}</button>
    <button class="o-btn__secondary"
            *ngIf="task?.CurrentExecution?.UserOutputMessages?.length > 0"
            (click)="onClickResultsButton()">{{ !showUserOutput ? ('Ver resultados' | t) : ('Ocultar resultados' | t)}}</button>
    <button id="btnCancel" class="o-btn__danger" *ngIf="task?.Cancelable && isRunning()"
            (click)="cancel()">{{ 'Cancelar' | t }}
    </button>
  </div>
  <div class="userOutput" *ngIf="showUserOutput && task?.CurrentExecution?.UserOutputMessages">

    <div class="flex-col-xs-12 flex-col-sm-12">
      <ul>
        <li *ngFor="let message of task?.CurrentExecution?.UserOutputMessages">
          <ng-container>{{message}}</ng-container>
        </li>
      </ul>
    </div>

  </div>
  <div class="item-footer">
    <button class="o-btn__danger" *ngIf="task?.Cancelable && isRunning()"
            (click)="cancel()">{{ 'Cancelar' | t }}
    </button>
    <button class="o-btn__danger" *ngIf="showCloseButton && task?.Cancelable && !isRunning()"
            (click)="close()">{{ 'Cerrar' | t }}
    </button>
  </div>
</div>
