import { Router } from '@angular/router';
import { getInSafe, isNullOrUndefined } from './typescript.utils';
import { Buffer } from 'buffer';

export class PrimeUtils {

  public static get DEFAULT_GUID(): string {
    return '00000000-0000-0000-0000-000000000000';
  };

  /**
   * Check if the id equals an C# default Guid
   */
  public static IsDefaultGuid(value: string): boolean {
    return value === this.DEFAULT_GUID;
  }

  public static E2EAreRunning(): boolean {
    if (sessionStorage.getItem('e2e-running') && sessionStorage.getItem('e2e-running') === 'true') {
      return true;
    }
    return false;
  }

  /**
   * Check if the string is a Guid
   * @param {string} value
   * @returns {boolean}
   * @constructor
   */
  public static IsGuid(value: string): boolean {
    return new RegExp('^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$').test(value);
  }

  /**
   * Get the device
   * @returns {string}
   */
  public static GetDevice(): string {
    let auxreturn: string = '';
    auxreturn += '{"platform":"';
    auxreturn += navigator.platform;
    auxreturn += '","browser":"';
    const browser: string[] = navigator.userAgent.split(' ');
    auxreturn += browser[browser.length - 2];
    auxreturn += ' '
    auxreturn += browser[browser.length - 1];
    auxreturn += '"}';
    return auxreturn;
  }

  /**
   * Navigates to the Boarding
   */
  public static NavigateToBoarding(router: Router, loginUrl: string): Promise<boolean> {
    if (!loginUrl.endsWith('/')) {
      loginUrl += '/';
    }
    return router.navigate([loginUrl + 'boarding']);
  }

  /**
   * Navigates to Maintence
   */
  public static NavigateToMaintenance = (router: Router): Promise<boolean> => router.navigate(['/maintenance']);

  /**
   * Navigates to Out Of Service
   */
  public static NavigateToOutOfService = (router: Router): Promise<boolean> => router.navigate(['/out-of-service']);

  /**
   * Get the Enum Name from the value
   * @param value
   * @param en
   * @constructor
   */
  public static GetEnumNameFromValue(value: number, en: any): string {
    for (const enumMember in en) {
      if (parseInt(enumMember, 10) === value) {
        return en[enumMember];
      }
    }
    return null;
  }

  public static ParseKeyItemToArray<T>(value: { [key: string]: any }): T[] {
    return parseKeyItemToArray(value);
  }

  public static GenerateApiConnection(configuration: any): string {
    let domain: string = getInSafe(configuration, c => c.domain, null);

    if (isNullOrUndefined(domain) || domain === '//') {
      domain = '//' + window.location.host;
      console.log('Backend Origin not defined in configuration. Take the current origin URL: ' + domain)
    }

    if (!domain.endsWith('/')) {
      domain += '/';
    }

    let api: string = getInSafe(configuration, c => c.api, null);
    if (!isNullOrUndefined(api) && api !== '') {
      console.log('Api exists: ' + api)
      if (api.startsWith('/')) {
        api = api.substring(1, api.length);
      }
      domain += api;
    }

    return domain;
  }
}

/**
 * Parses an array from a `Dictionary` (object).
 * @param {T} value
 */
export function parseKeyItemToArray<T>(value: { [key: string]: any }): T[] {
  return Object.keys(value).map((key) => {
    value[key]['key'] = key;
    return value[key];
  });
}

/**
 *  Converts the specified string, which encodes binary data as base-64 digits, to an equivalent string in utf8 format.
 *  @param str
 */
export function b64DecodeUnicode(str: string): string {
  return Buffer.from(str, 'base64').toString('utf8');
}
