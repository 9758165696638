/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { BatchTaskInfo } from '../models/ETG_SABENTISpro_Application_Core_models';
import { CoreBatchActionEnum } from '../models/ETG_SABENTISpro_Application_Core_models';
import { IBatchTaskResult } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class BatchService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Test run Job
  *
  * @param {string} id
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {BatchTaskInfo}
  */
  getTask(id: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<BatchTaskInfo>>
  {
    const params: any = {'id' : id};
    return this.generalService.get('core-batch/task', params, clientOpts) as Observable<WebServiceResponseTyped<BatchTaskInfo>>;
  }

  /**
  *
  * Execute a Job
  *
  * @param {string} pluginId
  * @param {string} serializedArgs
  * @param {any} reuse
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {BatchTaskInfo}
  */
  postScheduletask(pluginId: string, serializedArgs: string, reuse: any = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<BatchTaskInfo>>
  {
    const params: any = {'pluginId' : pluginId, 'serializedArgs' : serializedArgs, 'reuse' : JSON.stringify(reuse)};
    return this.generalService.post('core-batch/schedule-task', params, clientOpts) as Observable<WebServiceResponseTyped<BatchTaskInfo>>;
  }

  /**
  *
  * Execute a Job
  *
  * @param {string} pluginId
  * @param {string} serializedArgs
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @deprecated Utilizar ScheduleBatchTask
  * @returns {BatchTaskInfo}
  */
  postScheduletaskunique(pluginId: string, serializedArgs: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<BatchTaskInfo>>
  {
    const params: any = {'pluginId' : pluginId, 'serializedArgs' : serializedArgs};
    return this.generalService.post('core-batch/schedule-task-unique', params, clientOpts) as Observable<WebServiceResponseTyped<BatchTaskInfo>>;
  }

  /**
  * @param {string} queueId
  * @param {CoreBatchActionEnum} action
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postTaskaction(queueId: string, action: CoreBatchActionEnum, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'queueId' : queueId, 'action' : JSON.stringify(action)};
    return this.generalService.post('core-batch/task-action', params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Execute a Job
  *
  * @param {string} taskId
  * @param {string} contentDisposition
  * @param {any} args
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {IBatchTaskResult}
  */
  postTaskresult(taskId: string, contentDisposition: string, args: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<IBatchTaskResult>>
  {
    const params: any = {'taskId' : taskId, 'contentDisposition' : contentDisposition, 'args' : JSON.stringify(args)};
    return this.generalService.post('core-batch/task-result', params, clientOpts) as Observable<WebServiceResponseTyped<IBatchTaskResult>>;
  }
}
