import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { SabentisRouterLinkDirective } from './sabentis-router-link.directive';
import { NavigationService } from './navigation.service';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { takeUntil } from 'rxjs/operators';
import { NavigationRequest } from './models/NavigationRequest.class';
import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';
import { SabentisRouterLinkWithoutHrefDirective } from './sabentis-router-link-without-href.directive';

/**
 * IMPLEMENTACIÓN CUSTOM DE https://github.com/angular/angular/blob/master/packages/router/src/directives/router_link_active.ts
 * para que funcione con SabentisRouterLinkDirective y de acuerdo al árbol de navegación de backend (no a las rutas de frontend)
 */
@Directive({
  selector: '[appSabentisRouterLinkActive]',
  exportAs: 'appSabentisRouterLinkActive'
})
export class SabentisRouterLinkActiveDirective extends DestroyableObjectTrait implements OnChanges, OnDestroy, OnInit, AfterContentInit {

  @ContentChildren(SabentisRouterLinkDirective, {descendants: true})
  linksWithHrefsSabentis !: QueryList<SabentisRouterLinkDirective>;

  @ContentChildren(SabentisRouterLinkWithoutHrefDirective, {descendants: true})
  linksWithoutHrefsSabentis !: QueryList<SabentisRouterLinkWithoutHrefDirective>;

  // tslint:disable-next-line:member-ordering
  private classes: string[] = [];

  // tslint:disable-next-line:member-ordering
  public readonly isActive: boolean = false;

  @Input() routerLinkActiveOptions: { exact: boolean } = {exact: false};

  get menuItemResolved(): MenuItemCompiledFrontend {
    if (this.sabentisRouterLinkDirective) {
      return this.sabentisRouterLinkDirective.appSabentisRouterLink;
    }

    if (this.sabentisRouterLinkDirectiveWithoutHref) {
      return this.sabentisRouterLinkDirectiveWithoutHref.appSabentisRouterLink;
    }

    if (this.menuItem) {
      return this.menuItem;
    }

    throw new Error('Cannot find an available menu item.');
  }

  @Input() menuItem: MenuItemCompiledFrontend;

  constructor(
    private router: Router,
    private element: ElementRef,
    private renderer: Renderer2,
    private navigationService: NavigationService,
    @Optional() private sabentisRouterLinkDirective: SabentisRouterLinkDirective,
    @Optional() private sabentisRouterLinkDirectiveWithoutHref: SabentisRouterLinkWithoutHrefDirective) {
    super();
  }

  ngAfterContentInit(): void {
    this.linksWithHrefsSabentis.changes.subscribe(_ => this.update());
    this.linksWithoutHrefsSabentis.changes.subscribe(_ => this.update());
    this.update();
  }

  @Input()
  set appSabentisRouterLinkActive(data: string[] | string) {
    const classes: string[] = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }

  /**
   * Colgarnos de las actualizaciones
   */
  ngOnInit(): void {

    // Actualizar cada vez que navegamos
    this.navigationService.lastResolvedNavigationRequest
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((navigationRequest: NavigationRequest) => {
        this.doUpdate(navigationRequest);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  private update(): void {

    if (!this.router.navigated) {
      return;
    }
  }

  private doUpdate(navigationRequest: NavigationRequest): void {
    const hasActiveLinks: boolean = this.hasActiveLinks(navigationRequest);
    if (this.isActive === hasActiveLinks) {
      return;
    }

    (this as any).isActive = hasActiveLinks;

    this.classes.forEach((c) => {
      if (hasActiveLinks) {
        this.renderer.addClass(this.element.nativeElement, c);
      } else {
        this.renderer.removeClass(this.element.nativeElement, c);
      }
    });
  }

  private isLinkActive(): (navigationRequest: NavigationRequest, link: MenuItemCompiledFrontend) => boolean {
    return (navigationRequest: NavigationRequest, link: MenuItemCompiledFrontend) => this.navigationService.isNodeActive(navigationRequest, link);
  }

  private hasActiveLinks(navigationRequest: NavigationRequest): boolean {
    const isActiveCheckFn: (navigationRequest: NavigationRequest, link: MenuItemCompiledFrontend) => boolean = this.isLinkActive();
    return isActiveCheckFn(navigationRequest, this.menuItemResolved);
  }
}
