import { Injectable, isDevMode } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { BootstrapService } from './app-bootstrap.service';
import { DateTimeService } from './core/date-time/date-time.service';
import {
  GeocodingConfiguration,
  KeyValuePair,
  ThemeDefinitionCompiled
} from './core/models/ETG_SABENTISpro_Application_Core_models';
import { ClientThemeService } from './core/theme-manager/theme.service';
import { ThemeCustomizerService } from './shared/theme-customizer/theme-customizer.service';
import { DomReferenceProviderService } from './shared/utils/providers/dom-reference-provider.service';
import { getInSafe } from './shared/utils/typescript.utils';
import { PrimeUtils } from './shared/utils/prime.utils';
import { take } from 'rxjs/operators';
import { AppBootstrapSpinnerService } from './app-bootstrap-spinner.service';
import { GoogleAnalyticsClientSettings } from './core/models/ETG_SABENTISpro_Application_Modules_models';

@Injectable()
export class AppConfigurationService {

  /**
   * Store the key-value
   */
  config: Object = null;

  /**
   * Subject that alerts when the configuration has been bootstrapped.
   */
  public bootstrapDone$ = new ReplaySubject<boolean>(1);

  /**
   * Subject that alerts when the configuration params has been loaded.
   */
  private configDone$ = new ReplaySubject<boolean>(1);

  private generalConfig: any;
  private currentThemeConfig: ThemeDefinitionCompiled;
  private analyticsConfig: GoogleAnalyticsClientSettings;
  private geocodingConfiguration: GeocodingConfiguration;
  public systemLanguageCode: string;
  public systemLanguageIso: string;
  private timezoneConfig: { TimeZoneId: any, DateFormat: string, TimeFormat: string, DateAndTimeFormat: string };
  private activeModulesConfig: { nombre: string }[] = [];
  public errorPageIsDisabled: boolean = false;

  /**
   * Bootstrap configuration.
   */
  private bootstrapData: any;

  /**
   * domain value
   *
   * @private
   */
  private domain: string;

  /**
   * Creates a new instance of AppConfigurationService
   * @param {ThemeCustomizerService} themeCustomizarService
   * @param {ClientThemeService} clientThemeService
   * @param {DateTimeService} dateTimeService
   * @param {bootstrapService} bootstrapService
   * @param {DomReference} domReference
   */
  constructor(
    private themeCustomizarService: ThemeCustomizerService,
    private clientThemeService: ClientThemeService,
    private dateTimeService: DateTimeService,
    private bootstrapService: BootstrapService,
    private domReference: DomReferenceProviderService,
    private bootstrapSpinner: AppBootstrapSpinnerService
  ) {
    if (isDevMode()) {
      console.log('App configuration service started.');
    }
    this.loadConfiguration();
    this.loadBootstrapData();
  }

  /**
   * Retrieve the value for the input key
   */
  get(key: any): any {
    if (key === 'domain') {
      // Lo guardamos para que no inunde el log....
      if (!this.domain) {
        this.domain = PrimeUtils.GenerateApiConnection(this.config);
      }
      return this.domain;
    }
    return this.config[key];
  }

  /**
   * Retrieve the value of general config
   * @returns {any}
   */
  getGeneralConfig(): any {
    return this.generalConfig;
  }

  /**
   * Retrieve value of the current theme configuration
   * @returns {ThemeDefinitionCompiled}
   */
  getCurrentThemeConfig(): ThemeDefinitionCompiled {
    return this.currentThemeConfig;
  }

  /**
   * Retrieve Google Analytics Client Settings
   * @returns {GoogleAnalyticsClientSettings}
   */
  getAnalyticsConfig(): GoogleAnalyticsClientSettings {
    return this.analyticsConfig;
  }

  /**
   * Retrieve Google Analytics Client Settings
   * @returns {GoogleAnalyticsClientSettings}
   */
  getGeocodingConfiguration(): GeocodingConfiguration {
    return this.geocodingConfiguration;
  }

  /**
   * Retrieve timezone config
   * @returns {any}
   */
  getTimezoneConfig(): { TimeZoneId: any, DateFormat: string, TimeFormat: string, DateAndTimeFormat: string } {
    return this.timezoneConfig;
  }

  /**
   * Retrieve active modules configuration
   * @returns {{nombre: string}[]}
   */
  getActiveModulesConfig(): { nombre: string }[] {
    return this.activeModulesConfig;
  }

  /**
   * Loads bootstrap data
   */
  loadBootstrapData(): void {
    this.bootstrapService
      .bootDataReady$()
      .subscribe((responseData: any) => {

        if (isNullOrUndefined(responseData)) {
          this.triggerBootstrapDone(false);
          return false;
        }

        if (isDevMode()) {
          console.log('✔ Boot data ready.');
          console.log('⌛ Bootstrap started.');
        }

        this.currentThemeConfig = responseData.result['current-theme'];
        this.analyticsConfig = responseData.result['ga-settings'];
        this.timezoneConfig = responseData.result['system-timezone'] as { TimeZoneId: any, DateFormat: string, TimeFormat: string, DateAndTimeFormat: string };
        this.activeModulesConfig = responseData.result['active-modules'];
        this.geocodingConfiguration = responseData.result['geocoding-settings'];

        this.errorPageIsDisabled = responseData.result['error-page-disabled'];
        this.systemLanguageCode = responseData.result['system-language'];
        this.systemLanguageIso = responseData.result['system-languageIso'];

        this.bootstrapData = responseData.result;

        this.dateTimeService.setTimezone(this.timezoneConfig);

        this.themeCustomizarService.CurrentTheme = this.currentThemeConfig;
        if (this.currentThemeConfig) {
          this.clientThemeService.addTheme(this.currentThemeConfig)
            .subscribe(() => {
              const assemblyStyles: string[] = getInSafe(this.currentThemeConfig, x => x.AssemblyStyles, []);
              const customStyles: string[] = getInSafe(this.currentThemeConfig, x => x.UserStyles, []);

              if (assemblyStyles.length === 0 && customStyles.length === 0) {
                this.triggerBootstrapDone(true)
              }
              const themes: KeyValuePair<string, string>[] = [];

              assemblyStyles.forEach(x => {
                const customTheme: KeyValuePair<string, string> = new KeyValuePair<string, string>();
                customTheme.Key = 'assembly';
                customTheme.Value = this.get('domain') + 'core-theme/custom-style?assemblyStyle=' + encodeURIComponent(x);
                themes.push(customTheme)
              });

              customStyles.forEach(x => {
                const customTheme: KeyValuePair<string, string> = new KeyValuePair<string, string>();
                customTheme.Key = 'custom';
                customTheme.Value = this.get('domain') + 'core-files/fileref?fileRef=' + encodeURIComponent(x);
                themes.push(customTheme)
              });

              this.clientThemeService.loadThemes(themes).subscribe(() => this.triggerBootstrapDone(true))

            });
          return;
        } else {
          this.triggerBootstrapDone(true);
        }
      });
  }

  hasBootstrapData(): boolean {
    return !!this.bootstrapData;
  }

  /**
   * Returns a data set from the boostrap data object.
   */
  getBootstrapData(key: string): any {
    if (!this.bootstrapData.hasOwnProperty(key)) {
      return undefined;
    }

    return this.bootstrapData[key];
  }

  /**
   * Triggers the `bootstrapDone$` emitter, removing the body > .spinner-container
   * and informs the communication services (and others) that are allowed to
   * make requests.
   */
  triggerBootstrapDone(success: boolean): void {
    if (isDevMode()) {
      console.log('👌🏼 Bootstrap completed.');
    }
    this.bootstrapSpinner.remove();
    this.bootstrapDone$.next(success);
  }

  /**
   * Load "configuration.[environment].json" to get all variables (e.g.: 'assets/configuration/configuration.production.json')
   * Use the environment class to get the configuration file name.
   */
  loadConfiguration(): void {
    this.bootstrapService
      .configurationDataReady$()
      .subscribe((responseData) => {
        this.config = responseData;
        this.generalConfig = responseData;
        this.configDone$.next(true);

        if (isDevMode()) {
          console.log('✔ Configuration data ready.');
        }

        return (this.config);
      });
  }

  /**
   * Return a true boolean indicating if the configuration has been loaded.
   */
  isAppConfigLoaded$(): Observable<boolean> {
    return this.configDone$
      .asObservable()
      .filter(r => r)
      .take(1);
  }

  /**
   * Returns a true boolean indicating if apllication has been bootstrapped.
   */
  isAppBootstrapped$(): Observable<boolean> {
    return this.bootstrapDone$
      .pipe(
        take(1)
      );
  }
}
