import { LocationStrategy } from '@angular/common';
import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { get } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Subject } from 'rxjs/Subject';
import { isArray, isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { environment } from '../../../environments/environment';
import { getInSafe } from '../../shared/utils/typescript.utils';
import { HttpClient } from '@angular/common/http';
import { KeyValuePair, ThemeDefinitionCompiled } from '../models/ETG_SABENTISpro_Application_Core_models';
import * as jquery from 'jquery';
import urlJoin from 'proper-url-join';

@Injectable()
export class ClientThemeService implements OnDestroy {

  /**
   * Current theme.
   */
  private currentTheme: ThemeDefinitionCompiled;

  /**
   * Themes "activated" or loaded on the page.
   */
  private activatedThemes: KeyValuePair<string, string>[] = [];

  /**
   * Component destroy subject.
   */
  private destroyedComponent$: Subject<null> = new Subject<null>();

  /**
   * ClientThemeService class constructor.
   *
   * @param {HttpClient} http
   * @param {LocationStrategy} locationStrategy
   */
  constructor(
    private http: HttpClient,
    private locationStrategy: LocationStrategy
  ) {
  }

  /**
   * A lifecycle hook that is called when a directive, pipe, or service
   * is destroyed.
   *
   * Use for any custom cleanup that needs to occur when the
   * instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyedComponent$.next(null);
    this.destroyedComponent$.unsubscribe();
  }

  /**
   * Returns an array containing all the avalable themes css file paths.
   */
  getAvailableThemes(): Observable<KeyValuePair<string, string>[]> {
    return new Observable((obs: Observer<KeyValuePair<string, string>[]>) => {
      if (isDevMode() && environment['angularConfig']) {
        obs.next(this.getDevPaths());
        obs.complete();
      } else {
        this.getProdPaths().subscribe(paths => {
          obs.next(paths);
          obs.complete();
        });
      }
    });
  }

  /**
   * @param url
   * @private
   */
  private prepareExternalUrl(url: string): string {
    // El baseHREF registrado en el router de angular NO nos sirve, porque el servicio
    // está trampeado. Necesitamos el HREF exclusivo de los recursos estáticos.
    // const baseHref: string = this.locationStrategy.getBaseHref()
    const baseHref: string = jquery('head base').attr('href');
    return urlJoin(baseHref, this.locationStrategy.prepareExternalUrl(url));
  }

  /**
   * Returns available themes paths for production environment.
   */
  private getProdPaths(): Observable<KeyValuePair<string, string>[]> {
    const path: string = this.prepareExternalUrl('themes.json');

    return this.http.get(path)
      .retry(2)
      .catch(error => {
        return [];
      })
      .flatMap((response: Response) => {
        return new Observable((obs: Observer<KeyValuePair<string, string>[]>) => {
          const themes: any = [];
          const items: any = response;

          Object.getOwnPropertyNames(items).map(asset => {
            themes.push({
              Key: asset,
              Value: this.prepareExternalUrl(isArray(items[asset]) ? items[asset][0] : items[asset])
            } as KeyValuePair<string, string>);
          });

          obs.next(themes);
          obs.complete();
        });
      });
  }

  /**
   * Returns available themes paths for development environment.
   */
  private getDevPaths(): KeyValuePair<string, string>[] {
    const themes: KeyValuePair<string, string>[] = [];
    console.log(JSON.stringify(environment));
    const globalStyles: any[] = get(environment, 'angularConfig.projects.sabentisapp.architect.build.options.styles', []);
    const lazyStyles: void[] = globalStyles
      .filter(style => (typeof style === 'object') && style['input'])
      .map(style => {
        let src: any = style.input;
        src = src.substr(src.lastIndexOf('/') + 1).replace('scss', 'css');
        themes.push({
          Key: src,
          Value: this.prepareExternalUrl(src)
        } as KeyValuePair<string, string>);
      });


    return themes;
  }

  /**
   * Returns an array with active themes for current configuration.
   */
  public getActiveThemes(): KeyValuePair<string, string>[] {
    return this.activatedThemes;
  }

  /**
   * Get current theme Configuration : ThemeDefinitionCompiled
   */
  getCurrentTheme(): ThemeDefinitionCompiled {
    return this.currentTheme;
  }

  /**
   * Request for active themes info to backend route.
   */
  addTheme(theme: ThemeDefinitionCompiled): Observable<void> {
    return new Observable((obs: Observer<any>) => {
      this.currentTheme = theme;
      this.getAvailableThemes().subscribe(availableThemes => {
        const match: KeyValuePair<string, string> = availableThemes.find(available => available.Key.indexOf(theme.CssTheme) !== -1);
        if (!match) {
          throw new Error('The requested theme ' + theme.CssTheme + ' is not available in the compiled themes :' + availableThemes.join(','));
        }
        this.activatedThemes = [...this.activatedThemes, match];
        this.loadThemes(this.activatedThemes).subscribe(() => {
          obs.next(null);
          obs.complete();
        });
      });
    })
  }

  setTheme(theme: KeyValuePair<string, string>): void {
    this.activatedThemes = [...this.activatedThemes, theme];
  }

  /**
   * Given themes configuration objects, web them into the dom
   */
  loadThemes(themes: KeyValuePair<string, string>[]): Observable<void> {
    return new Observable((obs: Observer<void>) => {
      Observable.forkJoin(
        themes.map(theme => this.loadTheme(theme))
      ).subscribe(() => {
        obs.next(null);
        obs.complete();
      })
    });
  }

  loadTheme(theme: KeyValuePair<string, string>): Observable<void> {
    return new Observable((obs: Observer<void>) => {
      const head: HTMLHeadElement = document.head;
      const link: HTMLLinkElement = document.createElement('link');

      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = theme.Value;

      link.onerror = (_): void => {
        console.log('Error while loading CSS theme at bootstrap.');
        obs.next(null);
        obs.complete();
      }

      link.onload = (): void => {
        obs.next(null);
        obs.complete();
      }

      head.appendChild(link);
    });
  }

  /**
   * Removes a theme from the activated themes dictionary.
   * @param {string} identifier
   */
  public removeTheme(identifier: string): void {
    this.activatedThemes = this.activatedThemes.filter(item => item.Key.search(identifier) === -1);
  }

  /**
   * This handler works as a functional onload handler to replace `link.onload`.
   *
   * Note: this method **IS NOT** used as not all browsers supports
   * CSSStyleSheet objects. But it's safer than `object.onload` method as
   * validates that new css rules has been loaded.
   *
   * @param {Observer<void>} obs
   */
  private linkLoadListener(obs: Observer<void>, theme: KeyValuePair<string, string>): void {
    let styleSheet: CSSStyleSheet = null;

    // Poll every ten milliseconds to validate if the new stylesheet was added.
    Observable.timer(10, 10)
      .filter(i => {
        const documentSheets: StyleSheetList = document.styleSheets;
        const sheets: StyleSheet[] = [];

        for (let sheet: number = 0; sheet < documentSheets.length; sheet++) {
          sheets.push(documentSheets[sheet] as StyleSheet);
        }

        styleSheet = sheets.find(s => !isNullOrUndefined(s.href) && s.href.includes(theme.Value)) as CSSStyleSheet;

        return !isNullOrUndefined(styleSheet);
      })
      .take(1)
      .flatMap(() => {
        return Observable.timer(10, 10);
      }).filter(() => {
      const rules: number = getInSafe(styleSheet, s => s.cssRules.length, null);
      return !isNullOrUndefined(rules) && rules > 0;
    }).take(1)
      .subscribe(() => {
        obs.next(null);
        obs.complete();
      });
  }
}
