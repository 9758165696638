import { Type } from '@angular/core';
import { FormElementType } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { IFrontendFormElement } from '../interfaces/field.interface';
import { AutocompleteWrapperComponent } from './acomplete/autocomplete-wrapper/autocomplete-wrapper.component';
import { AutocompletenewWrapperComponent } from './autocompletenew/autocompletenew-wrapper/autocompletenew-wrapper.component';
import { ButtonComponent } from './button/button.component';
import { ChartEmbeddedComponent } from './chart-embedded/chart-embedded-wrapper/chart-embedded.component';
import { InputchipsWrapperComponent } from './chips/inputchips-wrapper/inputchips-wrapper.component';
import { DatePickerOldWrapperComponent } from './date-picker/date-picker-wrapper/date-picker-old-wrapper.component';
import { DatetimepickerWrapperComponent } from './date-time-picker/datetimepicker-wrapper/datetimepicker-wrapper.component';
import { DatepickerWrapperComponent } from './datepicker/datepicker-wrapper/datepicker-wrapper.component';
import { EmbeddedDocumentComponent } from './embedded-document/embedded-document.component';
import { FieldsetComponent } from './fieldset/fieldset.component';
import { FormElementFromContentsProgressComponent } from './formelementprogress/formelementprogress.component';
import { FormTableComponent } from './formtable/form-table.component';
import { HtmlplaceholderComponent } from './htmlplaceholder/htmlplaceholder.component';
import { InputCheckModalWrapperComponent } from './input-check-modal/input-check-modal-wrapper/input-check-modal-wrapper.component';
import { InputCheckComponent } from './input-check/input-check-wrapper/input-check.component';
import { InputFileWrapperComponent } from './input-file/input-file-wrapper/input-file.component';
import { SelectWrapperComponent } from './input-select/select-wrapper/select-wrapper.component';
import { InputTextAreaWrapperComponent } from './input-text-area/input-textarea-wrapper/input-textarea-wrapper.component';
import { InputWrapperComponent } from './input/input-wrapper/input-wrapper.component';
import { InputBoolWrapperComponent } from './inputbool/input-bool-wrapper/input-bool-wrapper.component';
import { LabelComponent } from './label/label-wrapper/label.component';
import { MapsInputWrapperComponent } from './map-input/map-input-wrapper/maps-input-wrapper.component';
import { MapsComponent } from './map/maps.component';
import { QrCodeComponent } from './qr-code/qr-code-wrapper/qr-code.component';
import { RadioWrapperComponent } from './radio/radio-wrapper/radio-wrapper.component';
import { SecretComponent } from './secret/secret.component';
import { TimePickerTimespanWrapperComponent } from './timepicker-timespan/timepicker-wrapper/time-picker-timespan-wrapper.component';
import { TimePickerWrapperComponent } from './timepicker/timepicker-wrapper/time-picker-wrapper.component';
import { ViewsEmbedComponent } from './views-embed/views-embed-wrapper/views-embed.component';
import { ViewsselectorwrapperComponent } from './viewsselector/viewsselectorwrapper/viewsselectorwrapper.component';
import { FormElementSimpleMessageComponent } from './simple-message/formelement-simple-message.component';
import { FormElementBatchTaskComponent } from './batch-task/form-element-batch-task.component';
import { InputNumberWrapperComponent } from './input-number/input-number-wrapper/input-number-wrapper.component';
import { InputAvatarWrapperComponent } from './input-avatar/input-avatar-wrapper/input-avatar.component';

/**
 * Mapeo entre los tipos de componente de backend, y las implementaciones en frontend.
 */
const components: { [type: number]: Type<IFrontendFormElement> } = {
  [FormElementType.Secret]: SecretComponent,
  [FormElementType.TextInput]: InputWrapperComponent,
  [FormElementType.Email]: InputWrapperComponent,
  [FormElementType.Number]: InputNumberWrapperComponent,
  [FormElementType.Checkbox]: InputCheckComponent,
  [FormElementType.Checkboxes]: InputCheckComponent,
  [FormElementType.CheckboxesModal]: InputCheckModalWrapperComponent,
  [FormElementType.Bool]: InputBoolWrapperComponent,
  [FormElementType.TextArea]: InputTextAreaWrapperComponent,
  [FormElementType.File]: InputFileWrapperComponent,
  [FormElementType.Select]: SelectWrapperComponent,
  [FormElementType.Date]: DatePickerOldWrapperComponent,
  [FormElementType.Password]: InputWrapperComponent,
  [FormElementType.Button]: ButtonComponent,
  [FormElementType.AutoComplete]: AutocompleteWrapperComponent,
  [FormElementType.Radio]: RadioWrapperComponent,
  [FormElementType.Label]: LabelComponent,
  [FormElementType.Map]: MapsComponent,
  [FormElementType.FieldSet]: FieldsetComponent,
  [FormElementType.Progress]: FormElementFromContentsProgressComponent,
  [FormElementType.HtmlPlaceholder]: HtmlplaceholderComponent,
  [FormElementType.ViewsEmbed]: ViewsEmbedComponent,
  [FormElementType.DatePicker]: DatepickerWrapperComponent,
  [FormElementType.TimePicker]: TimePickerWrapperComponent,
  [FormElementType.TagSelector]: InputchipsWrapperComponent,
  [FormElementType.ViewsSelector]: ViewsselectorwrapperComponent,
  [FormElementType.AutocompleteNew]: AutocompletenewWrapperComponent,
  [FormElementType.Table]: FormTableComponent,
  [FormElementType.QRCode]: QrCodeComponent,
  [FormElementType.TimePickerTimeSpan]: TimePickerTimespanWrapperComponent,
  [FormElementType.DateTimePicker]: DatetimepickerWrapperComponent,
  [FormElementType.MapInput]: MapsInputWrapperComponent,
  [FormElementType.EmbeddedDocument]: EmbeddedDocumentComponent,
  [FormElementType.ChartsEmbed]: ChartEmbeddedComponent,
  [FormElementType.Compound]: FieldsetComponent,
  [FormElementType.SimpleMessage]: FormElementSimpleMessageComponent,
  [FormElementType.BatchTask]: FormElementBatchTaskComponent,
  [FormElementType.Avatar]: InputAvatarWrapperComponent,
};

export { components };

