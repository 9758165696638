import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { ApplicationRef, Injectable, isDevMode } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { AppConfigurationService } from '../../app.configuration.service';
import { getInSafe, isNullOrWhitespace } from '../../shared/utils/typescript.utils';
import { MaintenanceControlService } from '../maintenance/maintenance-control.service';
import { MessageToastService } from '../message-toast/share/message-toast.service';
import { AppBootstrapSpinnerService } from '../../app-bootstrap-spinner.service';
import { WindowRef } from '../../shared/utils/browser-globals';
import { WebServiceResponse } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ContextUtils } from '../../shared/context/context.utils';

@Injectable()
export class ErrorCatcherInterceptor implements HttpInterceptor {

  constructor(
    private appRef: ApplicationRef,
    private appConfig: AppConfigurationService,
    private maintenanceControl: MaintenanceControlService,
    private windowRef: WindowRef,
    private messageToastService: MessageToastService,
    private bootstrapSpinner: AppBootstrapSpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const devMode: boolean = isDevMode();

    // Para poder comunicarnos con el interceptor, lo haremos a través
    // de unos headers custom que empiezan com meta-
    let newHeaders: HttpHeaders = req.headers;
    const customHeaderMetadata: object = [];
    for (const headerKey of req.headers.keys()) {
      if (!headerKey.startsWith('meta-')) {
        continue;
      }
      customHeaderMetadata[headerKey] = req.headers.get(headerKey);
      newHeaders = newHeaders.delete(headerKey);
    }
    req = req.clone({headers: newHeaders});

    return next.handle(req)
      .catch((res: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {

        // Esta cabecera hace que la gestión centralizda de errores sea ignorada
        // para esa petición
        if (customHeaderMetadata.hasOwnProperty('meta-donothandleerrors')) {
          return Observable.throwError(res);
        }

        // meta-codepassthrough -> 503,500
        // Esta cabecera nos permite indicarle al interceptor algunos códigos HTTP que debe
        // relanzar y no gestionar
        if (customHeaderMetadata.hasOwnProperty('meta-codepassthrough')) {
          const codes: string[] = customHeaderMetadata['meta-codepassthrough'].split(',');
          if (codes.find(((i) => res.status.toString() === i))) {
            // Relanzamos
            return Observable.throwError(res);
          }
        }

        // Resolve specific status cases.
        switch (res.status) {
          case 0:
            // Nunca deberíamos llegar aquí porque este error (sin conexión) se gestiona íntegramente
            // en el AppBootstrapInterceptor y eso pasa antes de este interceptor
            return Observable.throwError(res);
          case 401:
          case 403:
          case 413:
            return Observable.throwError(res);
          case 406:
            const requestCopy: HttpRequest<any> = ContextUtils.AddContextToHeaders(req);
            return next.handle(requestCopy);
          case 503:
            this.maintenanceControl.activateMaintenanceMode();
            return this.maintenanceControl.goToMaintenancePage$()
              .mergeMap(() => Observable.never());
        }

        const wsResponse: WebServiceResponse = getInSafe((res), (x) => x.error, null);

        // Estos casos son los errores de backend que vienen con mensaje o similar (i.e. BusinessRuleException)
        // en los que no intervendremos el flujo
        if ((res.status >= 500 && res.status < 600 || res.status === 422)
          && wsResponse
          && wsResponse.error
          && !isNullOrWhitespace(wsResponse.error.message)) {
          // Mandamos a consola para que quede registrado en los logs de IC
          console.error(JSON.stringify(wsResponse.error));

          if (this.appConfig.hasBootstrapData() === false) {
            this.bootstrapSpinner.setText('Error: ' + wsResponse.error.message);
          }
          // Si estoy en modo dev
          // o todavía no he arrancado (por lo cual no tengo toasts ni nada..)
          if (devMode) {
            // En dev mode modal, para que desarrollador no pueda pasarlo por alto!
            this.windowRef.getNativeWindow().alert(wsResponse.error.message + (wsResponse.error.stackTrace ?? ''));
          } else {
            // En productivo un toast, no usamos el servicio de modales porque hemos verificado que en etapas tempranas de arranque de la plataforma, no funciona.
            this.messageToastService.showWarning(wsResponse.error.message, 'Error', false);
          }

          // Devolvemos sin más
          return Observable.never();
        }

        return throwError(res);
      });
  }
}
