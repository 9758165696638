

export interface ITypedObject {
  readonly $type: string;
}

/**
 */
export interface IExtendEntity extends ITypedObject {
  extend: number[];
  Extend: { [id: string]: any };
}


/**
 */
export interface ISoftDeleted extends ITypedObject {
  deletedAt?: number;
}


/**
 */
export class QUALA_STUDY implements IExtendEntity, ISoftDeleted {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_STUDY';
  readonly $type: string = QUALA_STUDY.$type;
  COLMENA_QUALA_STUDY: any;
  fk_core_person_coresponsable?: string;
  CORE_PERSON_CORESPONSABLE: any;
  ier_locations: number[];
  si_questionnaireCompleted: boolean;
  id: string;
  fk_sabentis_company: string;
  quala: number;
  code: string;
  name: string;
  description: string;
  initialdate: number;
  enddate?: number;
  status: number;
  changedAt: number;
  deletedAt?: number;
  type: number;
  periodicity?: number;
  fk_core_person?: string;
  fk_quala_studytype?: string;
  startdate?: number;
  expirationdate?: number;
  copied: boolean;
  idExternal: string;
  fk_sabentis_division?: string;
  extend: number[];
  Extend: { [id: string]: any };
  conditions: number[];
  QUALA_IDENTIFIEDPROBLEMs: any[];
  QUALA_ANSWERs: any[];
  QUALA_STUDY_QUESTIONNAIREs: any[];
  QUALA_IDENTIFIEDNONCOMPLIANCEs: any[];
  QUALA_CORRECTIVEACTIONs: any[];
  QUALA_EVALUATIONTARGETs: any[];
  SABENTIS_COMPANY: any;
  CORE_PERSON: any;
  QUALA_STUDYTYPE: any;
  SABENTIS_DIVISION: any;
}


/**
 */
export class QUALA_QUESTIONNAIRE implements ISoftDeleted, IExtendEntity {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_QUESTIONNAIRE';
  readonly $type: string = QUALA_QUESTIONNAIRE.$type;
  achs_relatedgoal: string;
  id: string;
  quala: number;
  fk_quala_questionnairetype?: string;
  fk_quala_questionnairecategory?: string;
  code: string;
  name: string;
  description: string;
  configowner: string;
  changedAt: number;
  deletedAt?: number;
  fk_sabentis_scope?: string;
  extend: number[];
  deshabilitado: boolean;
  Extend: { [id: string]: any };
  QUALA_QUESTIONs: any[];
  QUALA_STUDY_QUESTIONNAIREs: any[];
  QUALA_QUESTIONNAIRE_PROBLEMs: any[];
  QUALA_QUESTIONNAIRETYPE: any;
  QUALA_QUESTIONNAIRECATEGORY: any;
  SABENTIS_SCOPE: any;
}


/**
 */
export class QUALA_PROBLEMCATEGORY implements ISoftDeleted {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_PROBLEMCATEGORY';
  readonly $type: string = QUALA_PROBLEMCATEGORY.$type;
  id: string;
  quala: number;
  code: string;
  name: string;
  description: string;
  changedAt: number;
  deletedAt?: number;
  fk_quala_problemcategory?: string;
  level?: number;
  QUALA_PROBLEMTYPEs: QUALA_PROBLEMTYPE[];
  QUALA_PROBLEMs: any[];
  QUALA_PROBLEMCATEGORY_fk_quala_problemcategory: QUALA_PROBLEMCATEGORY;
}


/**
 */
export class QUALA_PROBLEMTYPE implements ISoftDeleted {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_PROBLEMTYPE';
  readonly $type: string = QUALA_PROBLEMTYPE.$type;
  id: string;
  fk_quala_problemcategory?: string;
  quala: number;
  code: string;
  name: string;
  description: string;
  changedAt: number;
  deletedAt?: number;
  QUALA_PROBLEMs: any[];
  QUALA_PROBLEMCATEGORY: QUALA_PROBLEMCATEGORY;
}


/**
 */
export class CORE_GROUP implements ISoftDeleted {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Core.CORE_GROUP';
  readonly $type: string = CORE_GROUP.$type;
  id: string;
  code: string;
  name: string;
  description: string;
  hierarchy: number;
  supergroup: boolean;
  grouptype: number;
  profileType?: number;
  changedAt: number;
  deletedAt?: number;
  configowner: string;
  CORE_GROUPPERMISSIONs: any[];
  CORE_PERSON_GROUPs: any[];
}


/**
 */
export class CORE_QUEUE implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Core.CORE_QUEUE';
  readonly $type: string = CORE_QUEUE.$type;
  id: string;
  name: string;
  title: string;
  expire?: number;
  created: number;
  status: string;
  configuration: string;
  sandbox: string;
  owner: string;
  plugin: string;
  startedAt: number;
  batchTime: number;
  batchCount: number;
  log: string;
  lastRun?: number;
  lastTaken?: number;
  key: string;
  applicationLock: string;
  reuse?: number;
  timeOfLastStatusQuery?: number;
}


/**
 */
export enum CourseStatusEnum {
  EnMatriculacion = 0,
  EnProceso = 1,
  Finalizado = 2
}


/**
 */
export enum TraningStatusEnum {
  Pending = 0,
  Evaluated = 1
}


/**
 */
export enum AchsGESTareaFuenteExposicionOperacionEnum {
  Directa = 0,
  Indirecta = 1,
  Ambas = 2
}


/**
 */
export enum AchsGESEstadoEnum {
  EnCreacion = 0,
  PorEvaluar = 1,
  Evaluado = 2,
  EnProceso = 3
}


/**
 */
export enum AchsE11TraningStatusEnum {
  PendingRegistration = 0,
  Finalized = 1,
  Cerrado = 2
}


/**
 */
export enum CauseTypeEnum {
  Basica = 0,
  Immediata = 1
}


/**
 */
export enum CoreBatchReuseModeEnum {
  NoReuse = 0,
  ReuseByInstanceAndUser = 1,
  ReuseByInstance = 2
}

